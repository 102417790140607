import React from "react";
import { Link } from "react-router-dom";
import { LuSearchX } from "react-icons/lu";
import { MdOutlineDoNotDisturbAlt } from "react-icons/md";
export const ErrorPage = ({ err }) => {
  const httpErrors = {
    400: "Bad Request",
    401: "Unauthorized Access!",
    402: "Payment Required",
    403: "Unauthorized Access!",
    404: "Page Not Found!",
    405: "Method Not Allowed",
    406: "Not Acceptable",
    407: "Proxy Authentication Required",
    408: "Request Timeout",
    409: "Conflict",
    410: "Gone",
    411: "Length Required",
    412: "Precondition Failed",
    413: "Payload Too Large",
    414: "URI Too Long",
    415: "Unsupported Media Type",
    416: "Range Not Satisfiable",
    417: "Expectation Failed",
    418: "I'm a teapot",
    421: "Misdirected Request",
    422: "Unprocessable Entity",
    423: "Locked",
    424: "Failed Dependency",
    425: "Too Early",
    426: "Upgrade Required",
    428: "Precondition Required",
    429: "Too Many Requests",
    431: "Request Header Fields Too Large",
    451: "Unavailable For Legal Reasons",
    500: "Internal Server Error",
    501: "Not Implemented",
    502: "Bad Gateway",
    503: "Service Unavailable",
    504: "Gateway Timeout",
    505: "HTTP Version Not Supported",
    506: "Variant Also Negotiates",
    507: "Insufficient Storage",
    508: "Loop Detected",
    510: "Not Extended",
    511: "Network Authentication Required",
  };
  return (
    <div className="flex items-center justify-center font-secondary min-h-dvh bg-white py-48">
      <div className="flex flex-col">
        <div className="flex flex-col items-center">
          {err?.response?.status === 404 && (
            <LuSearchX color="#b90e0a" size={128} />
          )}
          {err?.response?.status === 403 && (
            <MdOutlineDoNotDisturbAlt color="#b90e0a" size={128} />
          )}
          {err?.response?.status !== 404 && err?.response?.status !== 403 && (
            <div className="text-red-600 font-bold text-7xl">
              {err?.response?.status}
            </div>
          )}

          <div className="font-bold text-3xl xl:text-6xl lg:text-5xl md:text-4xl mt-10">
            {httpErrors[err?.response?.status]}
          </div>
          <Link
            to="/"
            className="text-2xl hover:underline transition-all text-green-600 py-2 px-4 rounded-md mt-8"
          >
            Go to Homepage
          </Link>
        </div>
      </div>
    </div>
  );
};
