import React, { useState } from "react";
import { Page1 } from "./Page1";
import { Page2 } from "./Page2";
import { Page3 } from "./Page3";
import { LuMoveRight } from "react-icons/lu";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAuthContext } from "hooks/useAuthContext";
import { Page4 } from "./Page4";
import { Page5 } from "./Page5";
import { Helmet } from "react-helmet";
export const Amplify = () => {
  const [page, setPage] = useState(1);
  const location = useLocation();

  const { user } = useAuthContext();
  const { pathname, state } = useLocation();
  const navigate = useNavigate();
  const nextPage = () =>
    page !== 3
      ? setPage(page + 1)
      : user && user.kindness <= 0
      ? navigate("/send", {
          state: {
            prePath: pathname,
            apiData: state.apiData,
            compliment_link: state.compliment_link,
          },
        })
      : navigate("/login", {
          state: {
            prePath: pathname,
            apiData: state.apiData,
            compliment_link: state.compliment_link,
          },
        });

  return (
    <>
      <Helmet>
        <title>Amplify kindness!</title>
      </Helmet>
      {location.state && location.state.apiData ? (
        location.state.account_created ? (
          <div className="flex flex-col min-h-dvh justify-evenly items-center max-w-[400px] mx-auto px-10 font-light">
            <Page4
              from_name={location.state.apiData.from_person_details.first_name}
            />

            <button
              onClick={() => {
                navigate("/send", {
                  state: {
                    prePath: location.pathname,
                    apiData: location.state.apiData,
                    compliment_link: location.state.compliment_link,
                  },
                });
              }}
              className="w-48 flex items-center text-xl justify-center gap-2 uppercase bg-gray-900 text-white py-3 text-center rounded-xl hover:bg-gray-800 transition-all"
            >
              Next <LuMoveRight size={24} />
            </button>
          </div>
        ) : location.state.compliment_sent ? (
          <div className="flex flex-col min-h-dvh justify-evenly items-center max-w-[400px] mx-auto px-10 font-light">
            <Page5
              from_name={location.state.apiData.from_person_details.first_name}
            />

            <button
              onClick={() => {
                navigate(location.state.compliment_link, {
                  state: {
                    prePath: location.pathname,
                    apiData: location.state.apiData,
                    compliment_link: location.state.compliment_link,
                  },
                });
              }}
              className="w-48 flex items-center text-xl justify-center gap-2 uppercase bg-gray-900 text-white py-3 text-center rounded-xl hover:bg-gray-800 transition-all"
            >
              Next <LuMoveRight size={24} />
            </button>
          </div>
        ) : (
          <div>
            <div className="flex flex-col min-h-dvh justify-evenly items-center max-w-[400px] mx-auto px-10 font-light tablet:hidden">
              {page === 1 ? (
                <Page1 to_name={location.state.apiData.to_name} />
              ) : page === 2 ? (
                <Page2 />
              ) : (
                <Page3
                  picture={
                    location.state.apiData.from_person_details
                      .optional_data_json.picture
                  }
                  first_name={
                    location.state.apiData.from_person_details.first_name
                  }
                />
              )}

              <button
                onClick={nextPage}
                className="w-48 flex items-center text-xl justify-center gap-2 uppercase bg-gray-900 text-white py-3 text-center rounded-xl hover:bg-gray-800 transition-all"
              >
                Next <LuMoveRight size={24} />
              </button>
            </div>
            <div className="hidden tablet:flex justify-between">
              <div className="grid grid-cols-4 gap-x-8 place-items-center min-h-dvh mx-auto py-20 px-10 font-light">
                <Page1 to_name={location.state.apiData.to_name} />

                <Page2 />

                <Page3
                  picture={
                    location.state.apiData.from_person_details
                      .optional_data_json.picture
                  }
                  first_name={
                    location.state.apiData.from_person_details.first_name
                  }
                />
              </div>
              <div className="flex items-center justify-center bg-gray-200 px-12 lg:px-20">
                <button
                  onClick={() => {
                    user && user.kindness <= 0
                      ? navigate("/send", {
                          state: {
                            prePath: pathname,
                            apiData: state.apiData,
                            compliment_link: state.compliment_link,
                          },
                        })
                      : navigate("/login", {
                          state: {
                            prePath: pathname,
                            apiData: state.apiData,
                            compliment_link: state.compliment_link,
                          },
                        });
                  }}
                  className="w-48 flex items-center justify-center gap-2  bg-gray-900 text-white py-3 text-center rounded-xl hover:bg-gray-800 transition-all"
                >
                  Next <LuMoveRight size={24} />
                </button>
              </div>
            </div>
          </div>
        )
      ) : (
        <Navigate to="/" replace />
      )}
    </>
  );
};
