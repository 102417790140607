import React from "react";

const Button = ({ onClick, text, disabled = false, id, className = "" }) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      id={id}
      className={`w-full md:w-fit disabled:bg-gray-400 disabled:cursor-not-allowed  bg-gray-900 text-white py-3 text-center mobile:px-32 rounded-2xl hover:bg-gray-800 transition-all ${className}`}
    >
      {text}
    </button>
  );
};

export default Button;
