import api from "api";
import { amazing_bg, arrow_right, cute_bg, ogAmazing, ogCute } from "assets";
import Cue from "components/Cue";
import { useAuthContext } from "hooks/useAuthContext";
import { useCuesContext } from "hooks/useCuesContext";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ErrorPage } from "./ErrorPage";
import { Loader } from "components/Loaders/Loader";
import { Helmet } from "react-helmet";
import { useNavigationContext } from "hooks/useNavigationContext";
import { STRINGS } from "assets/strings";

export const CollectCompliment = ({ category }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { id } = useParams();

  const Auth = useAuthContext();
  const Navigation = useNavigationContext();
  const Cues = useCuesContext();

  const cueStore = Cues.cueStore;

  const [isLoading, setIsLoading] = useState(true);
  const [apiData, setApiData] = useState(null);
  const [cues, setCues] = useState([]);
  const [err, setErr] = useState(null);

  let hidden = false;

  if (apiData?.status.value === "SENT") {
    if (
      Auth.user === null ||
      Auth.user === undefined ||
      (Auth.user?.id !== apiData.from_person && Auth.user.kindness <= 0)
    ) {
      hidden = true;
    }
  } else hidden = false;

  useEffect(() => {
    if (Auth.auth_promise_resolved) {
      api
        .get(`view_received_compliment/${category.toLowerCase()}/${id}/`)
        .then((res) => {
          setApiData(res.data);
          if (
            res.data.status.value === STRINGS.COMPLIMENT_STATUS.SHOWN &&
            res.data?.to_person === Auth.user?.id &&
            Auth.user?.kindness > 0
          ) {
            Auth.dispatch({
              type: "RECEIVE_AND_SET_SHOWN",
            });
          }
          setCues(cueStore.filter((cue) => res.data.cues.includes(cue.emoji)));
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
          setErr(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, id, cueStore, Auth.auth_promise_resolved]);

  const color = category === STRINGS.CATEGORIES.AMAZING ? "yellow" : "pink";
  return isLoading || !Auth.auth_promise_resolved ? (
    <Loader />
  ) : !err ? (
    <>
      <Helmet>
        <title>
          {apiData.from_person_details.first_name}'s compliment for{" "}
          {apiData.to_name}
        </title>
        <meta
          name="description"
          content="ithinkyouare is the world's compliment app. Use it to give compliments to friends, colleagues, loved ones, and crushes, and make their day!"
        />
        <meta
          property="og:image"
          content={
            category === STRINGS.CATEGORIES.AMAZING ? { ogAmazing } : { ogCute }
          }
        ></meta>
      </Helmet>

      <div className="bg-gray-100 min-h-dvh overflow-x-hidden w-screen flex items-center flex-col justify-center px-4 py-40 md:py-20">
        <div className="lg:max-w-max w-11/12  lg:w-fit md:min-w-[400px] flex flex-col gap-4 items-center justify-center">
          <div className="bg-white rounded-xl  py-14 relative shadow-md shadow-gray-500 w-full">
            <img
              src={apiData.from_person_details.optional_data_json.picture}
              referrerPolicy="no-referrer"
              alt="user profile"
              className="w-28 absolute bottom-[calc(100%-3.5rem)] left-1/2 -translate-x-1/2 rounded-full"
            />
            <div className="flex flex-col items-center justify-center">
              <div
                className={`text-2xl font-bold px-6 py-5 rounded-lg relative flex items-center justify-center h-full`}
              >
                <div className="absolute inset-0 w-full h-full flex items-center justify-center">
                  <img
                    src={color === "yellow" ? amazing_bg : cute_bg}
                    alt="background"
                    className="h-16 z-0"
                  />
                </div>
                <span className="flex flex-col font-secondary text-3xl font-medium items-center justify-center z-10 gap-2">
                  <span>“I think you are </span>
                  <span
                    className={`lowercase ${
                      color === "yellow" ? "text-black" : "text-white"
                    }`}
                  >
                    {category}
                  </span>
                  <span> {apiData.to_name}!”</span>
                </span>
              </div>
              <div>You are...</div>

              <div
                className={`flex lg:grid ${
                  apiData.cues.length === 3
                    ? "grid-cols-3"
                    : apiData.cues.length === 2
                    ? "grid-cols-2"
                    : apiData.cues.length === 1
                    ? "grid-cols-1 items-center place-items-center"
                    : ""
                } gap-4 lg:gap-6 xl:gap-8 items-center lg:overflow-x-auto no-scrollbar max-w-full overflow-auto w-full px-4 lg:px-16 pt-8 pb-4`}
              >
                {apiData.cues.length > 0 &&
                  cues.map((cue, index) => (
                    <Cue
                      key={index}
                      emoji={cue.emoji}
                      text={cue.text}
                      hidden={hidden}
                      className="max-w-[300px] mx-auto w-full min-w-[calc(90%)] md:min-w-[60%] lg:min-w-auto shadow-gray-500 shadow-md"
                    />
                  ))}
              </div>
              {/* {apiData.text !== "" && ( */}
              <>
                <div
                  className={`w-full mx-8 text-center italic text-gray-700 font-medium pt-4 px-3 pb-2 relative ${
                    !hidden
                      ? ""
                      : "select-none after:absolute after:inset-0 after:w-full after:h-full after:backdrop-blur-sm"
                  }`}
                >
                  {apiData.text}
                  {
                    // ? apiData.text
                    // : user && !hidden
                    // ? ""
                    // : `It was a pleasure meeting you today ${apiData.to_name}`
                  }
                </div>
              </>
              {/* )} */}
            </div>
          </div>
          {(!Auth.user || Auth.user.id !== apiData.from_person) &&
            (apiData.status.value === STRINGS.COMPLIMENT_STATUS.SENT ||
              (apiData.status.value === STRINGS.COMPLIMENT_STATUS.SHOWN &&
                apiData.to_person === Auth.user?.id)) && (
              <button
                className="w-full py-6 px-3 hover:bg-button bg-black transition-all rounded-xl font-medium text-center text-lg leading-6 text-white shadow-md shadow-gray-500"
                onClick={() => {
                  if (hidden)
                    return navigate("/amplify", {
                      state: { compliment_link: location.pathname, apiData },
                    });
                  api
                    .patch(
                      `save_received_compliment/${category.toLowerCase()}/${id}/`
                    )
                    .catch((err) => setErr(err));
                  Auth.dispatch({
                    type: "RECEIVE_AND_SET_ACCEPTED",
                    payload: {
                      compliment: {
                        ...apiData,
                        status: { value: "ACCEPTED" },
                      },
                    },
                  });
                  Navigation.dispatch({
                    type: "SET_TAB_CATEGORY",
                    payload: {
                      tab: STRINGS.TABS.RECEIVED,
                      category,
                    },
                  });
                  navigate(`/`, { state: { category } });
                }}
              >
                {hidden ? "SHOW full" : "SAVE this"} compliment from{" "}
                {apiData.from_person_details.first_name.toUpperCase()}!
              </button>
            )}
        </div>

        {apiData.status.value === STRINGS.COMPLIMENT_STATUS.ACCEPTED ? (
          <span className="font-bold text-xl mt-8 text-green-600">
            Compliment saved{" "}
            {Auth.user?.id !== apiData.to_person
              ? "by " + apiData.to_person_details?.first_name || apiData.to_name
              : "to your account"}
            !
          </span>
        ) : (
          Auth.user?.id === apiData.from_person && (
            <div className="mt-8 flex items-end  justify-end sm:items-center sm:justify-center mx-auto relative w-2/3 sm:w-full">
              <img
                src={arrow_right}
                alt="right arrow"
                className="absolute -left-8 -top-2 sm:hidden"
              />
              <span className="font-bold text-xl sm:text-center text-right px-4">
                This is how your compliment will look to {apiData.to_name}
              </span>
            </div>
          )
        )}
      </div>
    </>
  ) : (
    <ErrorPage err={err} />
  );
};
