import { logo_transparent } from "assets";
import React from "react";

export const Carousel1 = () => {
  return (
    <>
      <div className="py-4 sm:py-2 mb-20 tablet:mb-0 border-b-4 border-t-4 sm:border-t-0 sm:border-b-[10px] border-y-gray-600 sm:border-b-black w-[calc(100%+100px)] h-fit overflow-hidden -rotate-[12deg] flex items-center justify-center">
        <img
          src={logo_transparent}
          alt="logo"
          className="w-3/5 sm:w-3/4 max-w-96 md:max-w-[512px]"
        />
      </div>
      <div className=" flex items-center justify-center gap-4 mt-20">
        <h1 className="text-2xl font-light">Hello Gorgeous!</h1>
      </div>
    </>
  );
};
