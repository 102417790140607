// api.js

import axios from "axios";
import { readFromLocalStorage, saveToLocalStorage } from "local_storage";

const api = axios.create({
  baseURL: "https://ithinkyoua.re/api",
});

// Function to set the authentication token in the headers
export const setAuthToken = () => {
  let access_token = readFromLocalStorage("access_token");
  if (access_token) {
    api.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;
    return true;
  } else {
    return false;
  }
};

export const deleteAuthToken = () => {
  delete api.defaults.headers.common["Authorization"];
};
export const saveAccessTokenToLocalStorage = (access_token) => {
  if (access_token) {
    saveToLocalStorage("access_token", access_token);
    setAuthToken();
  }
};
export default api;
