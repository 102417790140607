import React, { useEffect } from "react";
import Dashboard from "../../components/Dashboard";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { FaArrowUp } from "react-icons/fa";
import { Navbar } from "../../components/Navbar";
import { useAuthContext } from "hooks/useAuthContext";
import { Helmet } from "react-helmet";
import { Loader } from "components/Loaders/Loader";

const YourDashboard = () => {
  let navigate = useNavigate();
  const location = useLocation();

  // Clearing the location's state once the user is on dashboard (Eg. clearing the location.state containing the formData
  // of the Send Page, if he comes back from /send to / and then again goes to /send page, he should see a new form)
  useEffect(() => {
    location.state = null;
  }, [location]);
  const Auth = useAuthContext();
  return Auth.user ? (
    <>
      <Helmet>
        <title>{Auth.user.first_name}'s compliments</title>
        <meta
          name="description"
          content="ithinkyouare is the world's compliment app. Use it to give compliments to friends, colleagues, loved ones, and crushes, and make their day!"
        />
      </Helmet>
      {Auth.auth_promise_resolved ? (
        <div className="flex flex-col items-center">
          {/* Navbar component has 2 options - one with Navbar and one without (props passed as per use) */}
          <Navbar withLogo={true} />
          <div className="mb-20 mt-4 max-w-[400px] w-5/6 relative mx-auto">
            <Dashboard />
            <div className=" sticky max-w-[400px] w-fit mx-auto bottom-10">
              <button
                onClick={() => {
                  navigate("/send");
                }}
                name="send"
                className="bg-black font-secondary hover:bg-gray-800 transition-all text-white py-3 px-4 text-2xl font-medium tracking-tight gap-2 rounded-lg flex items-center"
              >
                SEND{" "}
                <span className=" rotate-45">
                  <FaArrowUp />
                </span>
              </button>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  ) : (
    <Navigate to="/" />
  );
};

export default YourDashboard;
