import React from "react";

export const Carousel2 = () => {
  return (
    <>
      <div className="text-9xl">💛</div>
      <div className=" flex items-center justify-center gap-4 mt-4 minh500:mt-8 minh500:sm:mt-20">
        <h1 className="text-lg sm:text-2xl font-light w-5/6 text-center">
          ithinkyouare is a{" "}
          <span className="font-bold">kindness amplifier</span> app where people
          drop compliments for each other!
        </h1>
      </div>
    </>
  );
};
