import { useAuthContext } from "hooks/useAuthContext";
import React from "react";

export const Page4 = ({ from_name }) => {
  const { user } = useAuthContext();
  return (
    <>
      <span className="text-9xl">🎉</span>
      <div className="flex flex-col gap-4 justify-center items-center text-center text-xl">
        <div>
          Good work, {user.first_name}! Your ithinkyouare account is created
        </div>
        <div>
          Now <span className="font-bold"> compliment someone else </span> to
          see your compliment from {from_name.toUpperCase()}!
        </div>
      </div>
    </>
  );
};
