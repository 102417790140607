import { LoginButton } from "components/LoginButton";
import React, { useEffect, useState } from "react";
import { Carousel1 } from "./Carousel1";
import { Carousel2 } from "./Carousel2";
import { Carousel3 } from "./Carousel3";
import { Navigate, useLocation } from "react-router-dom";
import { useAuthContext } from "hooks/useAuthContext";
import { Helmet } from "react-helmet";

export const Home = () => {
  const [page, setPage] = useState(1);
  const [isTimeoutActive, setIsTimeoutActive] = useState(true);

  const location = useLocation();
  const redirectPath = location.state ? location.state.path : "/";
  const { user } = useAuthContext();

  const timeOnEachPage = 4000; // in milliseconds

  useEffect(() => {
    let timeout;

    if (isTimeoutActive) {
      timeout = setTimeout(
        () => setPage(page > 3 ? page : page + 1),
        timeOnEachPage
      );
    }

    // Clear the timeout when the component unmounts or when isTimeoutActive changes
    return () => clearTimeout(timeout);
  }, [page, timeOnEachPage, isTimeoutActive]);

  return (
    <>
      <Helmet>
        <title>ithinkyouare</title>
        <meta 
          name="description"
          content="Welcome to the world's compliment app. Use it to give compliments to friends, colleagues, loved ones, and crushes, and make their day!"
        />
      </Helmet>

      {user ? (
        <Navigate to={redirectPath} replace />
      ) : (
        <div className="flex items-center justify-center  flex-col w-screen h-dvh -mt-16 overflow-hidden">
          {page === 1 ? (
            <Carousel1 />
          ) : page === 2 ? (
            <Carousel2 />
          ) : (
            <Carousel3 />
          )}
          <div className="absolute bottom-8 minh700:bottom-16 minh700:sm:bottom-28 flex items-center justify-center flex-col gap-2 minh500:gap-6">
            <div className="flex items-center justify-center gap-2">
              {[1, 2, 3].map((value) => (
                <span
                  key={value}
                  className={`rounded-full aspect-square w-4 block cursor-pointer ${
                    page === value ? "bg-gray-500" : "bg-gray-300"
                  }`}
                  onClick={() => {
                    setPage(value);
                    // Function to handle button click
                    setIsTimeoutActive(false);
                  }}
                ></span>
              ))}
            </div>
            <LoginButton />
          </div>
        </div>
      )}
    </>
  );
};
