import { instagram, messenger } from "assets";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const RedirectIntent = ({ platform }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Set the timeout for the redirect
    const timer = setTimeout(() => {
      let redirectUrl = "";
      switch (platform) {
        case "instagram":
          redirectUrl = "https://www.instagram.com/direct/inbox/";
          break;
        case "messenger":
          redirectUrl = "https://www.messenger.com/";
          break;
        default:
          console.log("Unsupported platform");
          // Optionally navigate the user back to a default page if the platform is unsupported
          navigate("/");
          return;
      }

      // Attempt to redirect to the app
      window.open(redirectUrl, "_blank");

      // Optionally navigate the user back to a safe page if the app does not open
      navigate("/share", {
        replace: true,
        state: { ...location.state, doneButtonEnabled: true },
      });
    }, 4000); // Adjust the delay as needed

    // Cleanup on component unmount
    return () => clearTimeout(timer);
  }, [platform, navigate, location.state]);
  return (
    <div className="h-[calc(100dvh-200px)] max-w-[300px] mobile:mx-auto gap-8 w-screen flex flex-col justify-center mobile:items-center px-10">
      <img
        src={platform === "instagram" ? instagram : messenger}
        alt={platform}
        className="w-10 aspect-square"
      />
      <div className="text-lg font-light mobile:text-center">
        Copied your compliment link to clipboard!
      </div>
      <div className="text-lg font-light mobile:text-center">
        Redirecting you to <span className="capitalize">{platform}</span>...
      </div>
    </div>
  );
};
