// File: localStorageFunctions.js

// Function to save a dictionary to local storage
export const saveToLocalStorage = (key, data) => {
  try {
    const serializedData = JSON.stringify(data);
    localStorage.setItem(key, serializedData);
  } catch (error) {
    console.error("Error saving to local storage:", error);
  }
};

// Function to read a dictionary from local storage
export const readFromLocalStorage = (key) => {
  try {
    const serializedData = localStorage.getItem(key);
    if (serializedData === null) {
      return null; // Return null if the key is not found
    }
    return JSON.parse(serializedData);
  } catch (error) {
    console.error("Error reading from local storage:", error);
    return null;
  }
};
export const clearLocalStorage = () => {
  localStorage.clear();
};
