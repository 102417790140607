import { STRINGS } from "assets/strings";
import { clearLocalStorage } from "local_storage";

export const authReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        user: action.payload.user,
        auth_promise_resolved: action.payload.auth_promise_resolved,
        all_compliments: action.payload.all_compliments,
      };
    case "PRIVACY":
      return {
        ...state,
        all_compliments: {
          ...state.all_compliments,
          [action.payload.tab]: state.all_compliments[action.payload.tab].map(
            (compliment) => {
              if (compliment.id === action.payload.compliment.id) {
                return {
                  ...compliment,
                  optional_data_json: {
                    ...compliment.optional_data_json,
                    [action.payload.privacy_key]: action.payload.privacy_value,
                  },
                };
              }
              return compliment;
            }
          ),
        },
      };
    case "SEND":
      return {
        ...state,
        user: {
          ...state.user,
          [STRINGS.TABS.SENT]: state.user[STRINGS.TABS.SENT] + 1,
          kindness: state.user.kindness + 1,
          category_counts: {
            ...state.user.category_counts,
            [STRINGS.TABS.SENT]: {
              ...state.user.category_counts[STRINGS.TABS.SENT],
              [action.payload.compliment.category.value]: {
                TOTAL:
                  state.user.category_counts[STRINGS.TABS.SENT][
                    action.payload.compliment.category.value
                  ].TOTAL + 1,
                HIDDEN:
                  action.payload.compliment.optional_data_json[
                    STRINGS.PRIVACY_KEYS.SENDER
                  ] === STRINGS.PRIVACY.PRIVATE
                    ? state.user.category_counts[STRINGS.TABS.SENT][
                        action.payload.compliment.category.value
                      ].HIDDEN + 1
                    : state.user.category_counts[STRINGS.TABS.SENT][
                        action.payload.compliment.category.value
                      ].HIDDEN,
              },
            },
          },
        },
        all_compliments: {
          ...state.all_compliments,
          [STRINGS.TABS.SENT]: [
            ...state.all_compliments[STRINGS.TABS.SENT],
            action.payload.compliment,
          ].sort((a, b) => new Date(b.created_at) - new Date(a.created_at)),
        },
      };
    case "RECEIVE_AND_SET_SHOWN":
      return {
        ...state,
        user: {
          ...state.user,
          [STRINGS.TABS.RECEIVED]: state.user?.[STRINGS.TABS.RECEIVED] + 1,
          kindness: state.user?.kindness - 1,
        },
      };
    case "RECEIVE_AND_SET_ACCEPTED":
      const category = action.payload.compliment.category.value;
      const cuesArr = action.payload.compliment.cues;
      const cue_counts = { ...state.user.cue_counts };
      cuesArr.forEach((emoji) => {
        cue_counts[emoji] = (cue_counts[emoji] || 0) + 1;
      });
      return {
        ...state,
        user: {
          ...state.user,
          category_counts: {
            ...state.user.category_counts,
            [STRINGS.TABS.RECEIVED]: {
              ...state.user.category_counts[STRINGS.TABS.RECEIVED],
              [category]: {
                TOTAL:
                  state.user.category_counts[STRINGS.TABS.RECEIVED][category]
                    .TOTAL + 1,
                HIDDEN:
                  action.payload.compliment.optional_data_json[
                    STRINGS.PRIVACY_KEYS.RECEIVER
                  ] === STRINGS.PRIVACY.PRIVATE
                    ? state.user.category_counts[STRINGS.TABS.RECEIVED][
                        category
                      ].HIDDEN + 1
                    : state.user.category_counts[STRINGS.TABS.RECEIVED][
                        category
                      ].HIDDEN,
              },
            },
          },
          cue_counts,
        },
        all_compliments: {
          ...state.all_compliments,
          [STRINGS.TABS.RECEIVED]: [
            ...state.all_compliments[STRINGS.TABS.RECEIVED],
            {
              ...action.payload.compliment,
              to_person_details: state.user,
              to_person: state.user.id,
            },
          ].sort((a, b) => new Date(b.created_at) - new Date(a.created_at)),
        },
      };
    case "LOGOUT":
      clearLocalStorage();
      return {
        user: null,
        auth_promise_resolved: true,
        all_compliments: {
          [STRINGS.TABS.SENT]: [],
          [STRINGS.TABS.RECEIVED]: [],
        },
      };
    default:
      return state;
  }
};
