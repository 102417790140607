import React, { useState } from "react";
import { FiArrowDownLeft, FiArrowUpRight } from "react-icons/fi";
import { CuesBar } from "./CuesBar";
import Category from "./Category";
import { useCuesContext } from "hooks/useCuesContext";
import { useAuthContext } from "hooks/useAuthContext";
import { useNavigationContext } from "hooks/useNavigationContext";
import { STRINGS } from "assets/strings";

const Dashboard = () => {
  const Auth = useAuthContext();
  const Navigation = useNavigationContext();
  // Sent and Received Tabs State
  const [selectedTab, setSelectedTab] = useState(Navigation.tab);

  const [isDashboardLoading] = useState(false);

  const { cueStore } = useCuesContext();

  const cueToText = cueStore.reduce((acc, curr) => {
    acc[curr.emoji] = curr.text;
    return acc;
  }, {});

  const cues = Object.entries(Auth.user?.cue_counts).map(([key, value]) => ({
    emoji: key,
    qty: value,
    text: cueToText[key],
  }));

  const compliments = {
    [STRINGS.TABS.RECEIVED]: {
      [STRINGS.CATEGORIES.AMAZING]: Auth.all_compliments?.[
        STRINGS.TABS.RECEIVED
      ]?.filter((ele) => ele.category.value === STRINGS.CATEGORIES.AMAZING),
      [STRINGS.CATEGORIES.CUTE]: Auth.all_compliments?.[
        STRINGS.TABS.RECEIVED
      ]?.filter((ele) => ele.category.value === STRINGS.CATEGORIES.CUTE),
    },
    [STRINGS.TABS.SENT]: {
      [STRINGS.CATEGORIES.AMAZING]: Auth.all_compliments?.[
        STRINGS.TABS.SENT
      ]?.filter((ele) => ele.category.value === STRINGS.CATEGORIES.AMAZING),
      [STRINGS.CATEGORIES.CUTE]: Auth.all_compliments?.[
        STRINGS.TABS.SENT
      ]?.filter((ele) => ele.category.value === STRINGS.CATEGORIES.CUTE),
    },
  };

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    Navigation.dispatch({
      type: "SET_TAB_CATEGORY",
      payload: {
        tab,
        category:
          compliments[tab][STRINGS.CATEGORIES.AMAZING].length > 0
            ? STRINGS.CATEGORIES.AMAZING
            : compliments[tab][STRINGS.CATEGORIES.CUTE].length > 0
            ? STRINGS.CATEGORIES.CUTE
            : STRINGS.CATEGORIES.AMAZING,
      },
    });
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between text-xl">
        {/* Mapping an array to render same type of button */}
        {[STRINGS.TABS.RECEIVED, STRINGS.TABS.SENT].map((tab, index) => (
          <button
            name={tab}
            key={index}
            // Styling according to Selected Tab
            className={`flex items-center justify-center w-full capitalize text-center py-3 transition-all border-b-4 cursor-pointer gap-2 rounded-t-md sm:hover:bg-gray-100  ${
              selectedTab === tab
                ? "border-black"
                : "border-transparent hover:border-gray-200"
            }`}
            onClick={() => {
              handleTabClick(tab);
            }}
          >
            {/* toLowerCase() used because this text is to be shown in UI */}
            {tab.toLowerCase()}{" "}
            {tab === STRINGS.TABS.RECEIVED ? (
              <FiArrowDownLeft size={24} />
            ) : (
              <FiArrowUpRight size={24} />
            )}
          </button>
        ))}
      </div>

      {/* Passing the user id to check whose cuesbar is to be rendered */}
      {cues.length !== 0 && selectedTab === STRINGS.TABS.RECEIVED && (
        <CuesBar userProp={"Your"} cues={cues} />
      )}

      {/* Passing the user id to check whose category is to be rendered */}
      <Category
        userProp={"self"}
        tab={selectedTab}
        isDashboardLoading={isDashboardLoading}
        compliments={compliments}
      />
    </div>
  );
};

export default Dashboard;
