import React from "react";

export const Page1 = ({ to_name }) => {
  return (
    <>
      <span className="text-9xl tablet:text-8xl lg:text-9xl tablet:col-span-1">
        💛
      </span>
      <div className="flex flex-col gap-4 justify-center tablet:items-start items-center text-center text-xl tablet:col-span-3 tablet:w-full tablet:text-start">
        <div>Hello {to_name}!</div>
        <div>
          ithinkyouare is a{" "}
          <span className="font-bold">kindness amplifier </span>app where people
          drop compliments for each other!
        </div>
      </div>
    </>
  );
};
