import { Navbar } from "components/Navbar";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "components/Button";
import { ThreeDots } from "react-loader-spinner";
import { useCuesContext } from "hooks/useCuesContext";

// import { useAuthContext } from "hooks/useAuthContext";
import { IoMdCheckmark, IoMdSearch } from "react-icons/io";
import { Helmet } from "react-helmet";
import { SearchCompliment } from "components/SearchCompliment";
import { HiLockOpen } from "react-icons/hi";
import { HiLockClosed } from "react-icons/hi";
import { STRINGS } from "assets/strings";

const SendCompliment = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const [open, setOpen] = useState(false);

  const to_name_ref = useRef();

  const { cueStore } = useCuesContext();

  // const { user } = useAuthContext();

  const initialValues = {
    to_name: "",
    category: STRINGS.CATEGORIES.AMAZING,
    optional_data_json: { privacy: STRINGS.PRIVACY.PUBLIC },
    cues: [],
    text: "",
  };
  const [formData, setFormData] = useState(
    location.state?.formData ? location.state.formData : initialValues
  );

  const [selectedCheckboxes, setSelectedCheckboxes] = useState(
    formData.cues.length
  );

  const [selectedQualitiesEmojis, setSelectedQualitiesEmojis] = useState(
    formData.cues
  );

  const setValues = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const trimOnLosingFocus = (e) => {
    const { name, value } = e.target;
    e.target.value = e.target.value.trim();

    setFormData({
      ...formData,
      [name]: value.trim(),
    });
  };

  const handleCheckboxChange = (event) => {
    const { checked } = event.target;
    let newSelectedCheckboxes = selectedCheckboxes;

    if (checked) {
      newSelectedCheckboxes++;
    } else {
      newSelectedCheckboxes--;
    }
    if (newSelectedCheckboxes > 3) {
      alert("You can select a maximum of 3 qualities.");
      event.target.checked = false;
      newSelectedCheckboxes--;
    } else {
      let updatedListOfEmo = [...selectedQualitiesEmojis];
      if (event.target.checked) {
        updatedListOfEmo = [...selectedQualitiesEmojis, event.target.value];
      } else {
        updatedListOfEmo.splice(
          selectedQualitiesEmojis.indexOf(event.target.value),
          1
        );
      }
      setSelectedQualitiesEmojis(updatedListOfEmo);
      const { name } = event.target;
      setFormData({ ...formData, [name]: updatedListOfEmo });
    }

    setSelectedCheckboxes(newSelectedCheckboxes);
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      formData.name === "" ||
      formData.cues.length === 0 ||
      formData.category === null
    )
      return alert("Fill all the required details!");
    if (isNameSameAsSender(formData.to_name))
      return alert(complimentSomeoneElseText(formData.to_name));

    navigate("/share", {
      // Save in Global State
      state: {
        formData,
        compliment_link: location.state?.compliment_link
          ? location.state?.compliment_link
          : null,
        apiData: location.state?.apiData ? location.state?.apiData : null,
      },
      replace: true,
    });
  };

  useEffect(() => {
    open
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset");
  }, [open]);

  const isNameSameAsSender = (form_name) => {
    return (
      location.state?.apiData?.from_person_details.first_name.toLowerCase() ===
      form_name.toLowerCase()
    );
  };
  const complimentSomeoneElseText = (name) =>
    `Whoops! You can't compliment ${name} back to see your compliment from them. Please compliment someone else!`;

  const visibleCueKeywords = (quality) =>
    quality.text
      .split(", ")
      .slice(
        0,
        quality.text.split(", ")[0].length +
          quality.text.split(", ")[1].length +
          quality.text.split(", ")[2].length <
          30
          ? 3
          : 2
      );

  const nameToBeShown =
    to_name_ref.current !== undefined &&
    !to_name_ref.current.matches(":focus") &&
    formData.to_name !== "" &&
    formData.to_name.charAt(0).toUpperCase() + formData.to_name.slice("1");
  return (
    <>
      <Helmet>
        <title>Send new compliment</title>
      </Helmet>
      <div className="w-11/12 mx-auto mb-20">
        <Navbar
          text={
            location.state?.compliment_link
              ? `Compliment Someone Else - To See Your Compliment From ${location.state?.apiData?.from_person_details.first_name}`
              : "Send new compliment"
          }
          back={location.state?.formData ? -3 : -1}
        />
        <form
          className="px-4 flex flex-col gap-8"
          onSubmit={handleSubmit}
          id="send_form"
        >
          <div className="flex flex-col justify-center gap-2">
            <label
              htmlFor="to_name"
              className="text-gray-700 flex items-center gap-4"
            >
              1. Receiver's name
              {to_name_ref.current !== undefined &&
                !to_name_ref.current.matches(":focus") &&
                formData.to_name !== "" &&
                !isNameSameAsSender(formData.to_name) && (
                  <IoMdCheckmark color="#4BB543" size={24} />
                )}
            </label>
            <input
              type="text"
              id="to_name"
              value={formData.to_name}
              ref={to_name_ref}
              onChange={setValues}
              onBlur={trimOnLosingFocus}
              required
              name="to_name"
              className={`${
                isNameSameAsSender(formData.to_name)
                  ? "outline-red-600 outline"
                  : ""
              } rounded-lg bg-gray-100 px-4 py-2 w-full max-w-[320px]`}
            />
            {isNameSameAsSender(formData.to_name) && (
              <div className="text-red-600 text-sm  w-full max-w-[320px]">
                {complimentSomeoneElseText(formData.to_name)}
              </div>
            )}
          </div>
          <div className="flex flex-col justify-center gap-2">
            <div className="text-gray-700 flex items-center gap-4">
              2. Compliment type
              {formData.category && <IoMdCheckmark color="#4BB543" size={24} />}
            </div>
            <div className="flex mobile:flex-row flex-col items-center gap-4">
              <input
                type="radio"
                id={STRINGS.CATEGORIES.AMAZING}
                value={STRINGS.CATEGORIES.AMAZING}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    category: e.target.value,
                    optional_data_json: { privacy: STRINGS.PRIVACY.PUBLIC },
                  });
                }}
                checked={formData.category === STRINGS.CATEGORIES.AMAZING}
                name="category"
                className="rounded-lg bg-gray-100 px-4 py-2 w-full max-w-[320px] hidden peer/AMAZING"
              />
              <label
                htmlFor={STRINGS.CATEGORIES.AMAZING}
                className="border w-full mobile:w-40 cursor-pointer rounded-lg flex flex-col px-4 justify-center py-2 opacity-60 peer-checked/AMAZING:opacity-100 peer-checked/AMAZING:ring peer-checked/AMAZING:ring-primary-yellow transition-all"
              >
                <div className="font-bold">amazing ⚡️</div>
                <span className="text-xs text-gray-500">
                  express admiration
                </span>
              </label>
              <input
                type="radio"
                id={STRINGS.CATEGORIES.CUTE}
                value={STRINGS.CATEGORIES.CUTE}
                name="category"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    category: e.target.value,
                    optional_data_json: { privacy: STRINGS.PRIVACY.PRIVATE },
                  });
                }}
                checked={formData.category === STRINGS.CATEGORIES.CUTE}
                className="rounded-lg bg-gray-100 px-4 py-2 w-full max-w-[320px] hidden peer/CUTE"
              />

              <label
                htmlFor={STRINGS.CATEGORIES.CUTE}
                className="border w-full mobile:w-40 cursor-pointer rounded-lg flex flex-col px-4 justify-center py-2 opacity-60 peer-checked/CUTE:opacity-100 peer-checked/CUTE:ring peer-checked/CUTE:ring-primary-pink transition-all"
              >
                <div className="font-bold">cute 💖</div>
                <span className="text-xs text-gray-500">flirt</span>
              </label>
            </div>
          </div>
          <div className="flex flex-col justify-center gap-2">
            <div className="text-gray-700 flex items-center gap-4">
              3. Privacy
              {formData.optional_data_json.privacy && (
                <IoMdCheckmark color="#4BB543" size={24} />
              )}
            </div>
            <div className="flex sm:flex-row flex-col items-center gap-4">
              {formData.category === STRINGS.CATEGORIES.AMAZING && (
                <>
                  <input
                    type="radio"
                    id={STRINGS.PRIVACY.PUBLIC}
                    value={STRINGS.PRIVACY.PUBLIC}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        optional_data_json: { privacy: e.target.value },
                      })
                    }
                    checked={
                      formData.optional_data_json.privacy ===
                        STRINGS.PRIVACY.PUBLIC &&
                      formData.category !== STRINGS.CATEGORIES.CUTE
                    }
                    disabled={formData.category === STRINGS.CATEGORIES.CUTE}
                    name="privacy"
                    className="rounded-lg bg-gray-100 px-4 py-2 hidden peer/public"
                  />
                  <label
                    htmlFor={STRINGS.PRIVACY.PUBLIC}
                    className="border w-full self-stretch sm:w-72 cursor-pointer rounded-lg flex gap-2 px-6 py-4 opacity-80 peer-checked/public:opacity-100 peer-checked/public:ring peer-checked/public:ring-black"
                  >
                    <div>
                      <HiLockOpen className="text-gray-300" size={24} />
                    </div>
                    <div className="flex flex-col gap-1">
                      <div className="font-bold text-sm">Public compliment</div>
                      <div className="text-xs text-gray-400 leading-snug">
                        Recommended! Compliment will be visible on{" "}
                        {nameToBeShown ? nameToBeShown + "'s" : "their"} public
                        profile. Lets other people know you think highly of
                        them!
                      </div>
                    </div>
                  </label>
                </>
              )}
              <input
                type="radio"
                id={STRINGS.PRIVACY.PRIVATE}
                value={STRINGS.PRIVACY.PRIVATE}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    optional_data_json: { privacy: e.target.value },
                  })
                }
                checked={
                  formData.optional_data_json.privacy ===
                    STRINGS.PRIVACY.PRIVATE ||
                  formData.category === STRINGS.CATEGORIES.CUTE
                }
                name="privacy"
                className="rounded-lg bg-gray-100 px-4 py-2 hidden peer/private"
              />

              <label
                htmlFor={STRINGS.PRIVACY.PRIVATE}
                className="border w-full self-stretch sm:w-72 cursor-pointer rounded-lg flex gap-2 px-6 py-4 opacity-80 peer-checked/private:opacity-100 peer-checked/private:ring peer-checked/private:ring-black"
              >
                <div>
                  <HiLockClosed className="text-red-200" size={24} />
                </div>
                <div className="flex flex-col gap-1">
                  <div className="font-bold text-sm">Private compliment</div>
                  <div className="text-xs text-gray-400 leading-snug">
                    Compliment will be visible to {nameToBeShown || "them"}, but
                    hidden from their public profile
                  </div>
                </div>
              </label>
            </div>
          </div>
          <div className="flex flex-col justify-center gap-2">
            <div className="text-gray-700 flex items-center gap-4">
              <div>
                4. Qualities you{" "}
                {formData.category === STRINGS.CATEGORIES.AMAZING
                  ? "admire"
                  : "find attractive"}{" "}
                in {nameToBeShown || "them"}{" "}
                <span className="text-gray-400">(upto 3)</span>
              </div>
              {formData.cues.length > 0 && (
                <IoMdCheckmark color="#4BB543" size={24} />
              )}
            </div>

            <div className="relative">
              <div
                onClick={() => {
                  if (formData.cues.length < 3) setOpen(true);
                }}
                className={`flex justify-between items-center rounded-lg my-4 bg-gray-100 text-gray-700 font-light px-3 py-1 w-full outline outline-none outline-offset-0 transition-all ${
                  formData.cues.length < 3
                    ? "cursor-pointer hover:outline-primary-yellow hover:bg-gray-200"
                    : "opacity-60 cursor-not-allowed"
                }`}
              >
                <span>
                  {formData.cues.length >= 3
                    ? "You can select upto 3 qualities."
                    : "Search across 200 qualities."}
                </span>
                <span className="px-2 mr-2 rounded-r-lg cursor-pointer my-1 py-1 border-l border-l-gray-500 ">
                  <IoMdSearch size={20} />
                </span>
              </div>
            </div>
            {cueStore.length === 0 ? (
              <div className="flex items-center justify-center w-full">
                <ThreeDots color="#000" />
              </div>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-2 minw1100:grid-cols-3 gap-4">
                {cueStore.map((quality, index) => (
                  <div key={index}>
                    <input
                      type="checkbox"
                      id={quality.emoji}
                      value={quality.emoji}
                      checked={formData.cues.includes(quality.emoji)}
                      disabled={
                        !formData.cues.includes(quality.emoji) &&
                        formData.cues.length >= 3
                      }
                      name="cues"
                      className={`peer hidden`}
                      onChange={handleCheckboxChange}
                    />

                    <label
                      htmlFor={quality.emoji}
                      className={`bg-gray-100 peer-disabled:bg-gray-50 peer-disabled:opacity-70 peer-disabled:hover:ring-0 peer-disabled:cursor-not-allowed peer-disabled:text-gray-600  cursor-pointer rounded-lg flex gap-3 hover:ring-gray-200 hover:ring items-center px-4 py-3 capitalize peer-checked:ring peer-checked:ring-black transition-all`}
                    >
                      <div className="text-lg md:text-xl">{quality.emoji}</div>
                      <span className="mobile:font-light text-sm md:text-base">
                        {visibleCueKeywords(quality).map(
                          (value, index) => (index === 0 ? "" : ", ") + value
                        )}
                      </span>
                    </label>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="flex flex-col justify-center gap-2">
            <label htmlFor="text" className="text-gray-700">
              5. Add text{" "}
              <span className="text-gray-400 text-sm">(optional)</span>
            </label>
            <textarea
              id="text"
              name="text"
              rows={4}
              value={formData.text}
              onChange={setValues}
              onBlur={trimOnLosingFocus}
              className="rounded-lg bg-gray-100 px-4 py-2 w-full md:w-1/2"
              placeholder="Upto 3-4 lines"
            />
          </div>

          <Button
            text={"Next"}
            id="send_compliment"
            className="font-medium text-lg"
          />
          {open && (
            <SearchCompliment
              setOpen={setOpen}
              open={open}
              formData={formData}
              handleCheckboxChange={handleCheckboxChange}
            />
          )}
        </form>
      </div>
    </>
  );
};

export default SendCompliment;
