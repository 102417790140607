import { user_img } from "assets";
import { useAuthContext } from "hooks/useAuthContext";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import * as updateLocale from "dayjs/plugin/updateLocale";
import { HiLockOpen } from "react-icons/hi";
import { HiLockClosed } from "react-icons/hi";
import { STRINGS } from "assets/strings";
import Dropdown from "./Dropdown";
import api from "api";

const ComplimentBox = ({
  compliment,
  tab = STRINGS.TABS.RECEIVED,
  userProp,
}) => {
  dayjs.extend(relativeTime);
  dayjs.extend(updateLocale);
  dayjs.updateLocale("en", {
    relativeTime: {
      past: "%s",
      s: "recently",
      m: "recently",
      mm: "recently",
      h: "recently",
      hh: "recently",
      d: "1d ago",
      dd: "%dd ago",
      M: "1m ago",
      MM: "%dm ago",
      y: "1y ago",
      yy: "%dy ago",
    },
  });
  const {
    cues,
    created_at,
    text,
    thanked = false,
    category,
    to_person_details,
    to_name,
    from_person_details,
    generated_link,
    optional_data_json,
    status,
  } = compliment;

  const Auth = useAuthContext();

  const privacy_key =
    tab === STRINGS.TABS.RECEIVED
      ? STRINGS.PRIVACY_KEYS.RECEIVER
      : STRINGS.PRIVACY_KEYS.SENDER;

  const color =
    category.value === STRINGS.CATEGORIES.AMAZING ? "yellow" : "pink";
  const { user } = useAuthContext();

  const name_to_be_shown =
    tab === STRINGS.TABS.RECEIVED
      ? from_person_details?.first_name
      : to_person_details?.first_name || to_name;

  const location = useLocation();
  return (
    <div
      className={`rounded-xl border border-gray-700 px-4 pt-4 ${
        thanked ? "pb-8" : "pb-4"
      } ${
        status.value !== "ACCEPTED" && "border-dashed"
      } flex flex-col gap-4 relative w-full`}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center w-3/5">
          <Link
            to={
              tab !== STRINGS.TABS.SENT
                ? from_person_details?.id === user.id
                  ? "/"
                  : `/${from_person_details?.username}`
                : to_person_details?.id === user.id
                ? "/"
                : to_person_details !== null
                ? `/${to_person_details?.username}`
                : location.pathname
            }
            state={{
              tab: tab === STRINGS.TABS.SENT ? tab : STRINGS.TABS.RECEIVED,
              category:
                category.value.toLowerCase() === STRINGS.CATEGORIES.AMAZING
                  ? STRINGS.CATEGORIES.AMAZING
                  : STRINGS.CATEGORIES.CUTE,
            }}
            className={`rounded-full overflow-hidden  aspect-square max-w-1/3 object-cover`}
          >
            {to_person_details ? (
              <img
                src={
                  tab !== STRINGS.TABS.SENT
                    ? from_person_details.optional_data_json.picture
                    : to_person_details !== null
                    ? to_person_details.optional_data_json.picture
                    : user_img
                }
                alt=""
                referrerPolicy="no-referrer"
                className="w-auto object-cover h-10"
              />
            ) : (
              <div
                className={`flex items-center justify-center aspect-square border-primary-${color} ${
                  color === "yellow" ? "border-[5px]" : "border-4"
                } rounded-full h-9 font-bold`}
              >
                {(to_person_details?.first_name || to_name)
                  .charAt(0)
                  .toUpperCase()}
              </div>
            )}
          </Link>
          <div className="w-max flex items-center">
            {cues &&
              cues.map((emoji, index) => (
                <div
                  key={index}
                  className={`rounded-full bg-white ${
                    color === "yellow" ? "border-[5px]" : "border-4"
                  } border-primary-${color} w-9 h-9 -ml-[6px] aspect-square flex items-center justify-center`}
                >
                  {emoji}
                </div>
              ))}
          </div>
        </div>
        <div className="flex items-center gap-2">
          {userProp === "self" && (
            <div className="flex items-center justify-center">
              <Dropdown
                options={
                  category.value === STRINGS.CATEGORIES.CUTE ||
                  (tab === STRINGS.TABS.RECEIVED &&
                    optional_data_json[STRINGS.PRIVACY_KEYS.SENDER] ===
                      STRINGS.PRIVACY.PRIVATE)
                    ? [
                        {
                          label: "Private",
                          desc: `Only ${
                            tab === STRINGS.TABS.RECEIVED
                              ? "you"
                              : to_person_details?.first_name || to_name
                          } can see this compliment`,
                          value: STRINGS.PRIVACY.PRIVATE,
                          icon: (
                            <HiLockClosed className="text-red-200" size={28} />
                          ),
                        },
                      ]
                    : [
                        {
                          label: "Public",
                          value: STRINGS.PRIVACY.PUBLIC,
                          desc: `Visitors on ${
                            tab === STRINGS.TABS.RECEIVED
                              ? "your"
                              : to_person_details?.first_name || to_name + "'s"
                          } profile can see this compliment`,
                          icon: (
                            <HiLockOpen className="text-gray-300" size={28} />
                          ),
                        },
                        {
                          label: "Private",
                          desc: `Only ${
                            tab === STRINGS.TABS.RECEIVED
                              ? "you"
                              : to_person_details?.first_name || to_name
                          } can see this compliment`,
                          value: STRINGS.PRIVACY.PRIVATE,
                          icon: (
                            <HiLockClosed className="text-red-200" size={28} />
                          ),
                        },
                      ]
                }
                chosenValue={optional_data_json?.[privacy_key]}
                onChangeCallback={(privacy_value) => {
                  api
                    .patch(
                      `change_compliment_privacy/${generated_link}/${privacy_value}/`
                    )
                    .catch((err) => {
                      console.log(err);
                    });
                  if (category.value === STRINGS.CATEGORIES.AMAZING) {
                    Auth.dispatch({
                      type: "PRIVACY",
                      payload: {
                        tab,
                        compliment,
                        privacy_value,
                        privacy_key,
                      },
                    });
                  }
                }}
              />
            </div>
          )}

          <div className="text-gray-400 font-light text-xs min-w-2/5 text-end flex flex-col items-end">
            <span>{name_to_be_shown}</span>
            <span>{dayjs(created_at).fromNow()}</span>
          </div>
        </div>
      </div>
      {text && (
        <div className="font-light text-gray-600 text-sm italic">"{text}"</div>
      )}
      {thanked && (
        <div className="px-4 py-1 rounded-full absolute right-6 top-[calc(100%-14px)] text-sm bg-gray-100">
          Thanked!
        </div>
      )}
    </div>
  );
};

export default ComplimentBox;
