import { STRINGS } from "assets/strings";

export const navigationReducer = (state, action) => {
  switch (action.type) {
    case "SET_TAB_CATEGORY":
      return {
        ...state,
        tab: action.payload.tab,
        category: action.payload.category,
      };
    case "RESET_TAB_CATEGORY":
      return {
        ...state,
        tab: STRINGS.TABS.RECEIVED,
        category: STRINGS.CATEGORIES.AMAZING,
      };
    default:
      return state;
  }
};
