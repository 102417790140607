import React from "react";
import { Link } from "react-router-dom";

export const CuesBar = ({ userProp, cues }) => {
  // cues array to be fetched from API

  return (
    <div className="  rounded-xl overflow-x-auto  bg-gray-100  hover:bg-gray-200 transition-all cursor-pointer w-full">
      {cues.length === 0 ? (
        <div></div>
      ) : (
        <Link
          to="qualities"
          state={{ user: userProp, cues }}
          className="flex items-center gap-3 py-2 px-4"
        >
          {cues.map((cue, index) => (
            <span className="gap-1 flex items-center" key={index}>
              {cue.emoji}
              <span className="font-bold text-sm">{cue.qty}</span>
            </span>
          ))}
        </Link>
      )}
    </div>
  );
};
