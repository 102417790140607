import { useContext } from "react";
import { CuesContext } from "../context/CuesContext";

export const useCuesContext = () => {
  const context = useContext(CuesContext);

  if (!context)
    throw Error("useCuesContext mush be wrapped inside an CuesContextProvider");
  return context;
};
