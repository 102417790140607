import { createContext, useReducer, useEffect } from "react";
import { authReducer } from "../reducers/authReducer";
import api, { setAuthToken } from "api";
import { STRINGS } from "assets/strings";

export const AuthContext = createContext();

export const fetchSelfDetailsAndDispatch = async (state, dispatch) => {
  try {
    const user_details_promise = api.get("/view_user_details/self/");
    const user_details = await user_details_promise;

    dispatch({
      type: "LOGIN",
      payload: {
        user: user_details.data,
        auth_promise_resolved: true,
        all_compliments: {
          [STRINGS.TABS.SENT]: user_details.data.compliments[
            STRINGS.TABS.SENT
          ]?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)),
          [STRINGS.TABS.RECEIVED]: user_details.data.compliments[
            STRINGS.TABS.RECEIVED
          ]?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)),
        },
      },
    });

    return user_details;
  } catch (err) {
    console.log(err);
    dispatch({
      type: "LOGOUT",
    });
  }
};

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
    all_compliments: { [STRINGS.TABS.SENT]: [], [STRINGS.TABS.SENT]: [] },
    auth_promise_resolved: false,
  });
  useEffect(() => {
    let success = setAuthToken();
    if (success) {
      fetchSelfDetailsAndDispatch(state, dispatch);
    } else {
      dispatch({
        type: "LOGOUT",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider value={{ ...state, state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
