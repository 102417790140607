import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { logo_transparent } from "assets";
import { BsArrowLeft } from "react-icons/bs";
import { useAuthContext } from "hooks/useAuthContext";
import { STRINGS } from "assets/strings";
import { useNavigationContext } from "hooks/useNavigationContext";
export const Navbar = ({
  withLogo,
  text,
  back = -1,
  disabled = false,
  disabledText = null,
}) => {
  // Global User Context
  const Auth = useAuthContext();
  const Navigation = useNavigationContext();

  const navigate = useNavigate();

  const location = useLocation();

  const sidebarOptions = {
    PROFILE: "profile",
    KINDNESS: "kindness",
  };

  // Sidebar state - null || sidebarOptions.PROFILE || sidebarOptions.KINDNESS
  const [sidebar, setSidebar] = useState(null);

  const signout = () => {
    Auth.dispatch({
      type: "LOGOUT",
    });
    Navigation.dispatch({
      type: "RESET_TAB_CATEGORY",
    });
    navigate("/");
    setSidebar(null);
  };

  // Turn off screen scrolling when Sidebar is open
  useEffect(() => {
    sidebar !== null
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset");
  }, [sidebar]);

  // Refs of buttons
  const picture_ref = useRef(null);
  const kindness_pts_ref = useRef(null);
  const sidebar_ref = useRef(null);
  // Close sidebar on outside click
  document.onclick = function (e) {
    if (
      (sidebar === sidebarOptions.PROFILE
        ? picture_ref.current && !picture_ref.current.contains(e.target)
        : kindness_pts_ref.current &&
          !kindness_pts_ref.current.contains(e.target)) &&
      !sidebar_ref.current.contains(e.target)
    ) {
      setSidebar(null);
    }
  };
  return withLogo ? (
    <div className="flex justify-between sticky top-0 z-[1000] shadow items-center w-full py-3 px-8 bg-slate-100 select-none">
      <Link to="/">
        <img src={logo_transparent} alt="Logo" className="h-10 w-auto" />
      </Link>
      {Auth.user && (
        <>
          <div className="flex items-center justify-center">
            <img
              className="rounded-full w-12 aspect-square cursor-pointer hover:opacity-85 transition-all"
              src={Auth.user.optional_data_json.picture}
              referrerPolicy="no-referrer"
              ref={picture_ref}
              onClick={() => {
                setSidebar(sidebarOptions.PROFILE);
              }}
              alt="circular"
            />
            <span
              ref={kindness_pts_ref}
              onClick={() => {
                setSidebar(sidebarOptions.KINDNESS);
              }}
              className="border-4 border-gray-400 p-4 hover:bg-gray-200 hover:border-gray-500 transition-all cursor-pointer rounded-full font-bold text-xl h-5 aspect-square flex items-center justify-center"
            >
              {Auth.user.kindness}
            </span>
          </div>
          <div
            className={`h-dvh w-screen bg-gray-800/80 flex items-center justify-center sm:justify-normal absolute inset-0 ${
              sidebar ? "opacity-100 scale-100" : "opacity-0 scale-0"
            } transition-opacity duration-500 ease-in-out`}
          >
            <div
              className={`sm:h-dvh flex flex-col justify-center items-center py-10 sm:pt-20 sm:pb-32 fixed gap-4 sm:top-0 rounded-2xl sm:rounded-none w-4/5 text-sm mobile:text-base sm:w-2/3 bg-white sm:max-w-[280px] shadow-2xl ${
                sidebar ? "sm:right-0" : "sm:-right-full"
              } transition-all duration-500 ease-in-out px-6 text-lg`}
              ref={sidebar_ref}
            >
              {sidebar === sidebarOptions.PROFILE ? (
                <>
                  <img
                    className="rounded-full w-20 aspect-square cursor-pointer"
                    src={Auth.user.optional_data_json.picture}
                    referrerPolicy="no-referrer"
                    alt="circular"
                  />
                  <div className="flex items-center justify-center flex-col">
                    <div>{Auth.user.first_name}</div>
                    <div className="text-sm text-gray-400">
                      {Auth.user.email}
                    </div>
                  </div>
                  <button
                    onClick={signout}
                    className="bg-gray-900 hover:bg-gray-800 transition-all text-white rounded-full py-3 sm:py-2 w-full text-sm"
                  >
                    Logout
                  </button>
                </>
              ) : (
                <div className="flex gap-4 sm:font-light flex-col">
                  <div>
                    You have{" "}
                    <span className="font-bold">
                      {Auth.user.kindness} kindness points
                    </span>
                    !
                  </div>
                  <ul className="ml-4 list-disc list-outside">
                    <li>Sent compliments: {Auth.user[STRINGS.TABS.SENT]}</li>
                    <li>
                      Received compliments: {Auth.user[STRINGS.TABS.RECEIVED]}
                    </li>
                    <li>
                      Kindness points: {Auth.user[STRINGS.TABS.SENT]}-
                      {Auth.user[STRINGS.TABS.RECEIVED]} = {Auth.user.kindness}
                    </li>
                  </ul>
                  <div>
                    ithinkyouare is fueled by{" "}
                    <span className="font-bold">kindness points</span>. Send
                    compliments to get points. Every point allows you to see one
                    of your received compliments!
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  ) : (
    <div className="w-full px-4  mx-auto flex justify-between py-8 text-2xl font-medium select-none ">
      <div
        onClick={() => {
          if (!disabled) navigate(back, { state: location.state });
        }}
        className={`${
          disabled
            ? "text-gray-400 cursor-not-allowed"
            : "cursor-pointer hover:gap-2"
        } has-tooltip flex gap-4   transition-all font-secondary`}
      >
        <span className="w-fit">
          <BsArrowLeft size={32} />
        </span>
        <span>{text}</span>
        {disabledText && disabled && (
          <span className="tooltip rounded-lg font-primary shadow-lg py-1 px-4 bg-gray-800 text-white mt-10 ml-12 font-normal text-xs ">
            {disabledText}
          </span>
        )}
      </div>
    </div>
  );
};
