import React from "react";

export const Page2 = () => {
  return (
    <>
      <span className="text-9xl lg:text-9xl tablet:text-8xl  tablet:col-span-1">
        🙌🏻
      </span>
      <div className="flex flex-col gap-4 justify-center items-center text-center tablet:text-start tablet:w-full text-xl tablet:col-span-3 tablet:items-start">
        <div>
          We have a simple rule: to see your compliment, you spread the love by
          dropping a compliment for someone else in your life!
        </div>
        <div>
          That's how we
          <span className="font-bold"> amplify kindness! </span>
        </div>
      </div>
    </>
  );
};
