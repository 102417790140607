import React from "react";

export const Page3 = ({ picture, first_name }) => {
  return (
    <>
      <img
        src={picture}
        referrerPolicy="no-referrer"
        alt="user"
        className="rounded-full w-32 mobile:w-48 tablet:w-32 aspect-square tablet:col-span-1"
      />
      <div className="flex justify-center items-center text-center tablet:text-start tablet:col-span-3 tablet:justify-normal tablet:items-start tablet:w-full">
        <div className="text-lg tablet:text-xl">
          Compliment someone else, to see your full compliment from{" "}
          {first_name.toUpperCase()}!
        </div>
      </div>
    </>
  );
};
