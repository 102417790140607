import api from "api";
import { Loader } from "components/Loaders/Loader";
import { Navbar } from "components/Navbar";
import { fetchSelfDetailsAndDispatch } from "context/AuthContext";
import { useAuthContext } from "hooks/useAuthContext";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { GiMale, GiFemale } from "react-icons/gi";
import { IoIosArrowDown } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import Datepicker from "react-tailwindcss-datepicker";
export const MyDetails = () => {
  const Auth = useAuthContext();

  const [isLoading, setIsLoading] = useState(false);

  const iniValues = {
    first_name: Auth.user.first_name,
    gender: "",
    dob: "",
  };

  const [date, setDate] = useState({
    startDate: null,
    endDate: null,
  });

  const [details, setDetails] = useState(iniValues);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const ref = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref?.current?.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [ref]);

  const { state, dispatch } = useAuthContext();
  const location = useLocation();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (details.first_name === "" || details.gender === "" || !details.dob)
      return alert("Fill all the details!");
    setIsLoading(true);
    api
      .patch("update_my_optional_data_json/", { optional_data_json: details })
      .then(() => fetchSelfDetailsAndDispatch(state, dispatch))
      .then(() => {
        setIsLoading(false);
        navigate(
          location.state && location.state.compliment_link ? "/amplify" : "/",
          {
            replace: true,
            state: {
              compliment_link:
                location.state && location.state.compliment_link
                  ? location.state.compliment_link
                  : null,
              account_created: true,
              apiData:
                location.state && location.state.apiData
                  ? location.state.apiData
                  : null,
            },
          }
        );
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  return (
    <>
      <Helmet>
        <title>My Details</title>
      </Helmet>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <Navbar withLogo={true} />
          <div className="mb-20 mt-4 md:mt-20 lg:mt-4 max-w-[400px] py-16 w-5/6 relative mx-auto flex flex-col gap-8 sm:items-center sm:justify-center">
            <span className="font-bold text-xl sm:text-3xl">Details</span>
            <form
              className="flex flex-col gap-8 text-lg sm:text-xl font-semibold w-full"
              id="mydetails_form"
            >
              <div className="sm:text-center flex flex-col gap-3 w-full">
                <label
                  htmlFor="name"
                  className="text-gray-500 font-light text-sm sm:text-base"
                >
                  First name
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={details.first_name}
                  onChange={(event) => {
                    setDetails({ ...details, first_name: event.target.value });
                  }}
                  className="sm:text-center w-full border-b pb-1 outline-none disabled:opacity-70 disabled:cursor-not-allowed"
                />
              </div>
              <div className="sm:text-center flex flex-col gap-3 w-full relative">
                <div className="text-gray-500 font-light text-sm sm:text-base">
                  Gender
                </div>
                <div ref={ref}>
                  <div
                    className="flex gap-2 items-center sm:justify-center pb-1 border-b relative cursor-pointer capitalize"
                    onClick={() => setOpen(!open)}
                  >
                    {details.gender === "" ? "Select" : details.gender}
                    {details.gender === "male" ? (
                      <GiMale size={20} />
                    ) : details.gender === "female" ? (
                      <GiFemale size={20} />
                    ) : (
                      <></>
                    )}
                    <IoIosArrowDown className="absolute right-2" />
                  </div>
                  <div
                    className={`${
                      open ? "h-max" : "h-0"
                    } overflow-hidden absolute top-full z-10 bg-gray-100 w-full mt-2 rounded-xl font-medium text-lg shadow capitalize`}
                  >
                    {["male", "female", "other"].map((value, index) => (
                      <div
                        key={index}
                        onClick={() => {
                          setOpen(false);
                          setDetails({ ...details, gender: value });
                        }}
                        className="py-2 hover:bg-gray-200 cursor-pointer flex sm:items-center gap-1 px-4 sm:justify-center"
                      >
                        {value}{" "}
                        {value === "male" ? (
                          <GiMale size={20} />
                        ) : value === "female" ? (
                          <GiFemale size={20} />
                        ) : (
                          <></>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="sm:text-center flex flex-col gap-3 w-full">
                <label
                  htmlFor="dob"
                  className="text-gray-500 font-light text-sm sm:text-base"
                >
                  Birthday
                </label>

                <Datepicker
                  useRange={false}
                  asSingle={true}
                  value={date}
                  inputClassName={
                    "pb-1 border-b w-full sm:text-center outline-none text-black font-semibold"
                  }
                  onChange={(value) => {
                    setDate(value);
                    setDetails({ ...details, dob: value.startDate });
                  }}
                />
              </div>
              <button
                type="submit"
                name="submit_mydetails"
                onClick={handleSubmit}
                className="sm:text-center py-4 mx-auto font-extrabold text-xl w-full sm:w-fit rounded-3xl bg-gray-900 px-12 shadow-xl mt-8 text-white"
              >
                Save
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};
