import React, { useEffect, useRef, useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { IoMdSearch } from "react-icons/io";

import { useCuesContext } from "hooks/useCuesContext";
import { HighlightedText } from "./HighlightedText";

export const SearchCompliment = ({
  setOpen,
  formData,
  handleCheckboxChange,
}) => {
  const [search, setSearch] = useState("");

  const { cueStore } = useCuesContext();

  const ref = useRef();

  const filteredCueStore = cueStore.filter((quality) =>
    quality.text.toLowerCase().includes(search.toLowerCase())
  );

  // Search input focused as soon as the component is rendered
  useEffect(() => {
    ref.current.focus();
  }, []);

  const handleCheckboxChangeSearchPage = (event) => {
    // event.target.checked is set to true (by javascript) as soon as it is clicked.
    // So we have to check if it is false initially (which means it was true when it was clicked).
    if (!event.target.checked) {
      // This sets the SearchCompliment Component to false -> Takes back to the Send Page
      setOpen(false);
      return;
    }
    handleCheckboxChange(event);
  };

  const visibleCueKeywords = (quality) =>
    quality.text
      .split(", ")
      .slice(
        0,
        quality.text.split(", ")[0].length +
          quality.text.split(", ")[1].length +
          quality.text.split(", ")[2].length <
          30
          ? 3
          : 2
      );
  return (
    <div className="w-screen h-dvh fixed inset-0 bg-white z-50 overflow-auto pb-20">
      <div className="w-11/12 mx-auto">
        <div className="w-full px-4  mx-auto flex justify-between py-8 text-2xl font-bold select-none ">
          <div
            onClick={() => setOpen(false)}
            className="flex items-center gap-4 cursor-pointer hover:gap-2 transition-all"
          >
            <BsArrowLeft size={32} />
            Search Qualities
          </div>
        </div>
        <div>
          <div className="rounded-lg flex justify-between overflow-hidden items-center gap-2 my-4 bg-gray-100  w-full  focus-visible:outline-primary-yellow">
            <input
              type="text"
              ref={ref}
              id="search"
              autoComplete="off"
              name="search"
              value={search}
              onChange={(e) => {
                const result =
                  e.target.value.charAt(0) === " "
                    ? e.target.value.replace(" ", "")
                    : e.target.value.charAt(0) === ","
                    ? e.target.value.replace(",", "")
                    : e.target.value;
                setSearch(result);
              }}
              className="disabled:cursor-not-allowed disabled:opacity-70 pl-4 py-2 placeholder:font-light outline-none bg-transparent border-none w-full focus-visible:bg-transparent"
              placeholder={"Search across 200 qualities"}
            />
            <span
              onClick={() => ref.current.blur()}
              className="px-2 mr-2 rounded-r-lg cursor-pointer my-1 py-1 border-l border-l-gray-500 hover:bg-gray-300 "
            >
              <IoMdSearch size={20} />
            </span>
          </div>
          <div className="flex flex-col gap-4">
            {search !== "" ? (
              filteredCueStore.length > 0 ? (
                filteredCueStore.map((quality, index) => (
                  <div key={index}>
                    <input
                      type="checkbox"
                      value={quality.emoji}
                      id={index}
                      name="cues"
                      disabled={
                        // formData.cues.includes(quality.emoji) ||
                        formData.cues.length >= 3
                      }
                      checked={formData.cues.includes(quality.emoji)}
                      className={`peer hidden`}
                      onChange={handleCheckboxChangeSearchPage}
                    />

                    <label
                      htmlFor={index}
                      className={`bg-gray-100   cursor-pointer rounded-lg flex flex-col gap-1 hover:ring-gray-200 hover:ring justify-center px-4 py-3 capitalize peer-checked:ring peer-checked:ring-black`}
                    >
                      <div
                        className={`flex gap-3 ${
                          !visibleCueKeywords(quality)
                            .join(", ")
                            .toLowerCase()
                            .includes(search.toLowerCase())
                            ? "items-start"
                            : "items-center"
                        }`}
                      >
                        <div className="text-lg md:text-xl">
                          {quality.emoji}
                        </div>
                        <div className="mobile:font-light text-sm md:text-base flex flex-col normal-case">
                          <div>
                            {/* {visibleCueKeywords(quality).map((value, index) => (
                              <span key={index}>
                                <HighlightedText
                                  value={(index === 0 ? "" : ", ") + value}
                                  highlight={search}
                                />
                                </span>
                              ))} */}
                            <HighlightedText
                              value={visibleCueKeywords(quality).join(", ")}
                              highlight={search}
                            />
                          </div>
                          <div>
                            {!visibleCueKeywords(quality)
                              .join(", ")
                              .toLowerCase()
                              .includes(search.toLowerCase()) &&
                              quality.text.split(", ")[
                                quality.text
                                  .split(", ")
                                  .map((qua) =>
                                    qua
                                      .toLowerCase()
                                      .includes(search.toLowerCase())
                                  )
                                  .indexOf(true)
                              ] && (
                                <div className="text-sm text-gray-500 normal-case">
                                  Similar to:{" "}
                                  <HighlightedText
                                    value={
                                      quality.text.split(", ")[
                                        quality.text
                                          .split(", ")
                                          .map((qua) =>
                                            qua
                                              .toLowerCase()
                                              .includes(search.toLowerCase())
                                          )
                                          .indexOf(true)
                                      ]
                                    }
                                    highlight={search}
                                  />
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </label>
                  </div>
                ))
              ) : (
                <div className="text-center  text-gray-500 py-2">
                  No such qualities
                </div>
              )
            ) : (
              <div className="text-center  text-gray-500 py-2">
                Search across more than 200 qualities
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
