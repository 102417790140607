import { createContext, useReducer } from "react";
import { navigationReducer } from "../reducers/navigationReducer";
import { STRINGS } from "assets/strings";

export const NavigationContext = createContext();

export const NavigationContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(navigationReducer, {
    tab: STRINGS.TABS.RECEIVED,
    category: STRINGS.CATEGORIES.AMAZING,
  });

  return (
    <NavigationContext.Provider value={{ ...state, state, dispatch }}>
      {children}
    </NavigationContext.Provider>
  );
};
