import { logo_transparent } from "assets";
import { LoginButton } from "../../components/LoginButton";
import { Helmet } from "react-helmet";

const Login = () => {
  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <div className="flex flex-col items-center justify-start mobile:justify-center pt-20 mobile:pt-0 h-dvh gap-8">
        {/* Logo */}
        <div className="flex flex-col items-center justify-center">
          <img src={logo_transparent} alt="Logo" className="w-48" />

          <div className="flex flex-col gap-2 items-center justify-center">
            {/* make someone's day */}
            <span className="text-gray-600 text-lg">make someone's day</span>
          </div>
        </div>
        <LoginButton />
      </div>
    </>
  );
};

export default Login;
