import React, { useEffect, useState } from "react";
import { Navbar } from "../components/Navbar";
import { CuesBar } from "../components/CuesBar";
import Category from "../components/Category";
import api from "api";
import { useParams } from "react-router-dom";
import { useCuesContext } from "hooks/useCuesContext";
import { Loader } from "components/Loaders/Loader";
import { ErrorPage } from "./ErrorPage";
import { Helmet } from "react-helmet";
import { STRINGS } from "assets/strings";

export const ConnectionProfile = () => {
  const Cues = useCuesContext();
  const [err, setErr] = useState(null);
  const [compliments, setCompliments] = useState([]);
  const [userinfo, setUserinfo] = useState({});
  const [cues, setCues] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { username } = useParams();

  const cueStore = Cues.cueStore;

  const cueToText = cueStore.reduce((acc, curr) => {
    acc[curr.emoji] = curr.text;
    return acc;
  }, {});

  useEffect(() => {
    api
      .get(`view_user_details/username_${username}/`)
      .then(async (res) => {
        setUserinfo(res.data);
        setCompliments({
          [STRINGS.TABS.RECEIVED]: {
            [STRINGS.CATEGORIES.AMAZING]: res.data?.compliments?.[
              STRINGS.TABS.RECEIVED
            ]
              ?.filter(
                (ele) => ele.category.value === STRINGS.CATEGORIES.AMAZING
              )
              ?.sort((a, b) => b.created_at - a.created_at),
            [STRINGS.CATEGORIES.CUTE]: res.data?.compliments?.[
              STRINGS.TABS.RECEIVED
            ]
              ?.filter((ele) => ele.category.value === STRINGS.CATEGORIES.CUTE)
              ?.sort((a, b) => b.created_at - a.created_at),
          },
        });
        setCues(
          Object.entries(res.data?.cue_counts).map(([key, value]) => ({
            emoji: key,
            qty: value,
            text: cueToText[key],
          }))
        );
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setErr(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isLoading ? (
    <Loader />
  ) : !err ? (
    <>
      <Helmet>
        <title>{userinfo.first_name}'s compliments</title>
      </Helmet>
      <div className="w-11/12 max-w-[440px] mx-auto">
        <Navbar text={userinfo.first_name} back="/" />
        <div className="flex items-center justify-center flex-col gap-4 w-5/6  mx-auto">
          <img
            src={userinfo.optional_data_json.picture}
            referrerPolicy="no-referrer"
            alt="user"
            className="rounded-full w-32 mobile:w-48 aspect-square"
          />

          <>
            {cues.length !== 0 && <CuesBar userProp={userinfo} cues={cues} />}
          </>

          <Category userProp={userinfo} compliments={compliments} />
        </div>
      </div>
    </>
  ) : (
    <ErrorPage err={err} />
  );
};
