import { useEffect, useRef, useState } from "react";
const Dropdown = ({ disabled, options, chosenValue, onChangeCallback }) => {
  const [selectedOption, setSelectedOption] = useState(chosenValue);
  // Dropdown menu
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setSelectedOption(() => chosenValue);
  }, [chosenValue]);

  const handleSelectChange = (value) => {
    setSelectedOption(value);
    onChangeCallback(value);
    setOpen(false);
  };
  // Turn off screen scrolling when Dropdown is open
  useEffect(() => {
    open
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset");
  }, [open]);

  // Refs of buttons
  const dropdown_ref = useRef(null);

  // Close dropdown menu on outside click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!dropdown_ref?.current?.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [dropdown_ref]);

  return (
    <>
      <div className="relative items-center flex w-full">
        <div
          className={`text-sm flex items-center justify-center ${
            disabled ? "cursor-not-allowed" : "cursor-pointer  hover:opacity-80"
          } transition-all`}
          onClick={() => !disabled && setOpen(true)}
        >
          {options.filter((option) => option.value === selectedOption)[0]?.icon}
        </div>
      </div>
      {open && !disabled && (
        <div className="fixed w-screen h-dvh overflow-hidden bg-gray-800/80 inset-0 z-[1001] flex items-center justify-center">
          <div
            ref={dropdown_ref}
            style={{ height: options.length > 1 ? "14rem" : "6.4rem" }}
            className="max-w-[80%] mobile:max-w-[300px] w-fit  bg-white rounded-xl overflow-hidden shadow"
          >
            {options.map((option) => (
              <div
                key={option.value}
                style={{ height: (1 / options.length) * 100 + "%" }}
                className={` w-full cursor-pointer flex gap-2 px-8 items-center transition-all ${
                  selectedOption === option.value
                    ? "bg-gray-200/80"
                    : "hover:bg-gray-100"
                }`}
                onClick={() => handleSelectChange(option.value)}
              >
                <div className="w-full flex gap-2">
                  <div>{option.icon}</div>
                  <div className="flex flex-col gap-1">
                    <div className="font-bold text-sm">{option.label}</div>
                    <div className="text-xs text-gray-500">{option.desc}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Dropdown;
