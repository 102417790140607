import { createContext, useReducer, useEffect } from "react";
import { cuesReducer } from "../reducers/cuesReducer";
import api from "api";

export const CuesContext = createContext();

export const CuesContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cuesReducer, { cueStore: [] });

  useEffect(() => {
    api
      .get(`/view_all_cues/`)
      .then((res) => {
        dispatch({
          type: "FETCH",
          payload: res.data,
        });
      })
      .catch((err) => console.log(err));
    console.log("Fetching from cues");
  }, []);

  return (
    <CuesContext.Provider value={{ ...state, dispatch }}>
      {children}
    </CuesContext.Provider>
  );
};
