import { Navbar } from "components/Navbar";
import { copy, instagram, messenger, telegram, whatsapp } from "assets";
import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Button from "components/Button";
import { IoMdCheckmark } from "react-icons/io";
import { VscError } from "react-icons/vsc";
import { Helmet } from "react-helmet";
import api from "api";
import { useAuthContext } from "hooks/useAuthContext";
import { useNavigationContext } from "hooks/useNavigationContext";
import { STRINGS } from "assets/strings";
const Share = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const Auth = useAuthContext();
  const Navigation = useNavigationContext();

  const formData = location.state.formData;

  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(false);

  const [copied, setCopied] = useState(false);
  const [intent, setIntent] = useState(false);
  const [newTabOpen, setNewTabOpen] = useState(false);

  const [platform, setPlatform] = useState(null);

  const [link, setLink] = useState(null);

  const socials = [
    {
      name: "Whatsapp",
      img: whatsapp,
      // onClick: (link) => {
      //   window.open(`https://wa.me/?text=${link}`, "_blank");
      // },
      onClick: () => handleShare("whatsapp"),
    },
    {
      name: "Instagram Direct",
      img: instagram,
      onClick: () => handleShare("instagram"),
    },
    {
      name: "Telegram",
      img: telegram,
      // onClick: (link) => {
      //   window.open(
      //     `https://t.me/share/url?url=${link}`, //&text=YOUR_TEXT
      //     "_blank"
      //   );
      // },
      onClick: () => handleShare("telegram"),
    },
    {
      name: "Messenger",
      img: messenger,
      onClick: () => handleShare("messenger"),
    },
  ];
  useEffect(() => {
    if (localStorage.getItem("genLink"))
      setLink(window.location.origin + `/` + localStorage.getItem("genLink"));
  }, []);

  // Function to generate the link by making an API call
  const generateLink = async () => {
    setIsLoading(true);
    try {
      const res = await api.post("give_compliment/", location.state.formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      localStorage.setItem("genLink", res.data.generated_link);
      let genLink = window.location.origin + `/` + res.data.generated_link;
      setLink(genLink);
      setIsLoading(false);
      return genLink; // Resolve the promise with the generated link
    } catch (err) {
      setIsLoading(false);
      setErr(true);
      console.log(err);
    }
  };

  const shareApiPopupMobile = async (generatedLink, platform) => {
    const isLaptop =
      /Macintosh|Windows|Linux/i.test(navigator.userAgent) &&
      !/Mobi/.test(navigator.userAgent); // Check if the user is on a laptop

    const shareData = {
      title: "ithinkyouare",
      text: `${Auth.user.first_name}'s compliment for ${formData?.to_name}`,
      url: generatedLink,
    };

    if (isLaptop || !navigator.canShare) {
      navigate("/share/redirect/" + platform, {
        state: location.state,
      });
    } else if (navigator.canShare && navigator.canShare(shareData)) {
      try {
        await navigator.share(shareData);
        console.log("shared");
      } catch (err) {
        console.log(`Error: ${err}`);
      }
    }
  };

  // Function to handle sharing or copying the link
  const handleShare = async (platform) => {
    setPlatform(platform);
    try {
      // Check if the user is on an iOS device or Safari
      const isIOS =
        /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        navigator.userAgent
      );
      let generatedLink = link; // Initialize generatedLink with the current link value

      // Generate link by making an API call only if the link is not available
      if (
        !link &&
        // If link is null and the device is not an iOS device or Safari
        ((!isIOS && !isSafari) ||
          // If link is null and the device is an iOS device or Safari,
          // but the platform is not "instagram", "messenger", or "copy"
          ((isIOS || isSafari) &&
            platform !== "instagram" &&
            platform !== "messenger" &&
            platform !== "copy"))
      ) {
        generatedLink = await generateLink();
        setLink(generatedLink);
      }

      // Copy the link to the clipboard if the user clicks on Instagram, Messenger, or the Copy button
      if (
        platform === "instagram" ||
        platform === "messenger" ||
        platform === "copy"
      ) {
        try {
          if ((isIOS || isSafari) && !generatedLink) {
            // Fallback to Clipboard API
            navigator.clipboard.write([
              new ClipboardItem({
                "text/plain": new Promise(async (resolve) => {
                  try {
                    const res = await api.post(
                      "give_compliment/",
                      location.state.formData
                    );

                    localStorage.setItem("genLink", res.data.generated_link);
                    let genLink =
                      window.location.origin + `/` + res.data.generated_link;
                    setLink(genLink);
                    setIsLoading(false);
                    resolve(new Blob([genLink], { type: "text/plain" }));
                  } catch (err) {
                    setIsLoading(false);
                    setErr(true);
                    console.log(err);
                  }
                }),
              }),
            ]);
          } else {
            await navigator.clipboard.writeText(generatedLink);
          }
          if (platform === "instagram" || platform === "messenger")
            shareApiPopupMobile(generatedLink, platform);
          setCopied(err ? false : true);
        } catch (error) {
          setErr(true);
          setIsLoading(false);
          setCopied(false);
          console.error("Clipboard API error:", error);
          // Handle clipboard errors as needed
        }
      }

      // Redirect the user to the respective social media platform or copy the link otherwise
      if (generatedLink) {
        switch (platform) {
          case "whatsapp":
            setIntent(true);
            window.open(
              `https://wa.me/?text=${encodeURIComponent(generatedLink)}`,
              "_blank"
            );
            break;
          // case "instagram":
          //     shareApiPopupMobile(generatedLink, platform);
          //   break;
          case "telegram":
            setIntent(true);
            window.open(
              `https://t.me/share/url?url=${encodeURIComponent(generatedLink)}`,
              "_blank"
            );
            break;
          // case "messenger":
          //     // navigate("/share/redirect/messenger", { state: location.state });
          //     shareApiPopupMobile(generatedLink, platform);
          //   break;
          case "copy":
            // No need to do anything here since the link is already copied
            break;
          default:
            throw new Error("Unsupported platform");
        }
      }
    } catch (error) {
      console.error("Error sharing:", error);
      // Handle errors as needed
    }
  };

  const sent = localStorage.getItem("genLink");

  useEffect(() => {
    window.addEventListener("blur", () => {
      setNewTabOpen(copied || intent ? true : false);
    });
  }, [copied, intent]);
  const isEnabled = err || ((copied || intent) && newTabOpen);
  return location.state ? (
    <>
      <Helmet>
        <title>Share compliment</title>
      </Helmet>
      <div className="mx-6 sm:mx-20 mb-16">
        <Navbar
          text={`Share with ${location.state.formData?.to_name}`}
          back={"/send"}
          disabled={sent}
          disabledText={"Compliment already sent"}
        />

        <div className="flex flex-col gap-2 items-start text-lg mb-8">
          {/* Buttons */}
          {socials.map((social, index) => (
            <button
              key={index}
              name={social.name}
              onClick={social.onClick}
              className="flex gap-4 items-center hover:bg-gray-100 transition-all rounded-md pl-4 w-full sm:w-2/3 md:w-1/3 sm:min-w-96 py-4"
            >
              <img alt={social.name} src={social.img} className="w-12" />
              <span>{social.name}</span>
            </button>
          ))}

          <button
            onClick={() => handleShare("copy")}
            name="copy_btn"
            disabled={isLoading && platform === "copy"}
            className={`flex gap-4 ${
              err && platform === "copy"
                ? "text-red-600"
                : copied && platform === "copy"
                ? "text-[#4BB543] font-bold"
                : ""
            } items-center hover:bg-gray-100 transition-all rounded-md pl-4 w-full sm:w-2/3 md:w-1/3 sm:min-w-96 py-4 disabled:cursor-not-allowed disabled:opacity-60 disabled:hover:bg-white`}
          >
            {err && platform === "copy" ? (
              <div className="w-12 aspect-square flex items-center justify-center">
                <VscError color="rgb(220 38 38)" size={40} />
              </div>
            ) : copied && platform === "copy" ? (
              <div className="w-12 aspect-square flex items-center justify-center">
                <IoMdCheckmark color="#4BB543" size={40} />
              </div>
            ) : (
              <img alt="copy" src={copy} className="w-12 aspect-square" />
            )}

            <span>
              {platform === "copy"
                ? isLoading
                  ? "Copying..."
                  : err
                  ? "Error"
                  : copied
                  ? "Copied"
                  : "Copy"
                : "Copy"}
            </span>
          </button>
        </div>
        <Button
          text={"Done!"}
          className="font-medium text-lg"
          onClick={() => {
            if (!err) {
              Auth.dispatch({
                type: "SEND",
                payload: {
                  compliment: {
                    category: { value: STRINGS.CATEGORIES[formData.category] },
                    created_at: new Date().toISOString(),
                    cues: formData.cues,
                    from_person: Auth.user.id,
                    from_person_details: Auth.user,
                    generated_link: localStorage.getItem("genLink"),
                    status: { value: "SENT" },
                    text: formData.text,
                    to_name: formData?.to_name,
                    optional_data_json: {
                      SENDER_PRIVACY: formData.optional_data_json.privacy,
                    },
                  },
                },
              });
              localStorage.removeItem("genLink");
            }
            Navigation.dispatch({
              type: "SET_TAB_CATEGORY",
              payload: {
                tab: STRINGS.TABS.SENT,
                category: STRINGS.CATEGORIES[formData.category],
              },
            });
            navigate(location.state?.compliment_link ? "/amplify" : `/`, {
              state: {
                apiData: location.state?.apiData
                  ? location.state.apiData
                  : null,
                compliment_link: location.state?.compliment_link
                  ? location.state.compliment_link
                  : null,
                compliment_sent: true,
              },
            });
          }}
          id={"done"}
          disabled={!(location.state?.doneButtonEnabled || isEnabled)}
        />
        {/* {sent && <ComplimentSent name={location.state.formData.to_name} />} */}
      </div>
    </>
  ) : (
    <Navigate to="/send" />
  );
};

export default Share;
