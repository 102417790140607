import React from "react";

import "./index.css";
import App from "./App";
import { AuthContextProvider } from "context/AuthContext";
import { CuesContextProvider } from "context/CuesContext";
import { BrowserRouter } from "react-router-dom";
import { NavigationContextProvider } from "context/NavigationContext";
import { hydrate, render } from "react-dom";


const APP = (
  <BrowserRouter>
    <NavigationContextProvider>
      <AuthContextProvider>
        <CuesContextProvider>
          <App />
        </CuesContextProvider>
      </AuthContextProvider>
    </NavigationContextProvider>
  </BrowserRouter>
)

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  hydrate(APP, rootElement);
} else {
  render(APP, rootElement);
}

