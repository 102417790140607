import React from "react";
import { ThreeDots } from "react-loader-spinner";

export const Loader = () => {
  return (
    <div className="fixed z-[1000] inset-0 bg-white/80 w-screen h-dvh flex items-center justify-center">
      <ThreeDots
        visible={true}
        height="80"
        width="80"
        color="#000"
        radius="9"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </div>
  );
};
