import { useContext } from "react";
import { NavigationContext } from "../context/NavigationContext";

export const useNavigationContext = () => {
  const context = useContext(NavigationContext);

  if (!context)
    throw Error(
      "useAuthContext mush be wrapped inside an NavigationContextProvider"
    );
  return context;
};
