import React from "react";

import Cue from "components/Cue";
import { Navbar } from "components/Navbar";
import { useLocation } from "react-router-dom";

const YourCues = () => {
  const location = useLocation();
  const user = location.state.user;
  const cues = location.state.cues;

  return (
    <div className="w-11/12 max-w-[500px] mx-auto sm:mt-10 mb-20">
      <Navbar
        text={`${user === "Your" ? user : user.first_name + "'s"} Qualities`}
      />
      <div className="flex items-center justify-center flex-col gap-4 w-5/6  mx-auto">
        {cues.map((cue, index) => (
          <Cue key={index} emoji={cue.emoji} text={cue.text} qty={cue.qty} />
        ))}
      </div>
    </div>
  );
};

export default YourCues;
