import { STRINGS } from "assets/strings";
import React from "react";

export const CategoryBar = ({
  setCategory,
  category,
  tab,
  compliments = {
    RECEIVED: { AMAZING: [], CUTE: [] },
    SENT: { AMAZING: [], CUTE: [] },
  },
  category_counts,
}) => {
  return (
    <div className="flex border-4 rounded-xl overflow-hidden border-gray-500 mb-4 font-bold text-lg">
      <div
        className={`w-full text-center py-1 px-1 mobile:px-4  border-r-4 border-gray-500 cursor-pointer flex items-center justify-center  transition-all  ${
          category === STRINGS.CATEGORIES.AMAZING
            ? "bg-gray-200 "
            : "text-gray-400 hover:bg-gray-100"
        }`}
        onClick={() => {
          setCategory(STRINGS.CATEGORIES.AMAZING);
        }}
      >
        amazing {category === STRINGS.CATEGORIES.AMAZING ? "⚡️" : ""}{" "}
        {category_counts[tab][STRINGS.CATEGORIES.AMAZING].TOTAL !== 0 &&
          category_counts[tab][STRINGS.CATEGORIES.AMAZING].TOTAL}
      </div>
      <div
        className={`w-full text-center py-1 px-1 mobile:px-4 cursor-pointer flex items-center justify-center transition-all ${
          category === STRINGS.CATEGORIES.CUTE
            ? "bg-gray-200 "
            : "text-gray-400 hover:bg-gray-100"
        }`}
        onClick={() => {
          setCategory(STRINGS.CATEGORIES.CUTE);
        }}
      >
        cute {category === STRINGS.CATEGORIES.CUTE ? "💖" : ""}{" "}
        {category_counts[tab][STRINGS.CATEGORIES.CUTE].TOTAL !== 0 &&
          category_counts[tab][STRINGS.CATEGORIES.CUTE].TOTAL}
      </div>
    </div>
  );
};
