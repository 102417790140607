import { useAuthContext } from "hooks/useAuthContext";
import React from "react";

export const Page5 = ({ from_name }) => {
  const { user } = useAuthContext();
  return (
    <>
      <span className="text-9xl">🤗</span>
      <div className="flex flex-col gap-4 justify-center items-center text-center text-xl">
        <div>Thanks for spreading the love, {user.first_name}!</div>
        <div>You are awesome 🙌🏼</div>
        <div>
          Meanwhile, here&apos;s
          <span className="font-bold">
            {" "}
            {from_name}&apos;s full compliment{" "}
          </span>{" "}
          for you...
        </div>
      </div>
    </>
  );
};
