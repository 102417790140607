import React from "react";

export const HighlightedText = ({ value, highlight }) => {
  const getHighlightedText = (text, highlight) => {
    if (!highlight || !text) {
      return text;
    }

    const regex = new RegExp(`(${highlight})`, "gi");

    const tokens = text.split(regex);

    return tokens.map((token, index) =>
      regex.test(token) ? (
        <span key={index} className="font-bold text-gray-600">
          {token}
        </span>
      ) : (
        token
      )
    );
  };

  return <span>{getHighlightedText(value, highlight)}</span>;
};
