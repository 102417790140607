import YourDashboard from "Pages/DashboardPages/YourDashboard";
import { ConnectionProfile } from "Pages/ConnectionProfile";
import SendCompliment from "Pages/SendPages/SendCompliment";
import Share from "Pages/SendPages/Share";
import React, { useEffect } from "react";
import { Routes, Route, Navigate, useLocation, Outlet } from "react-router-dom";
import YourCues from "Pages/DashboardPages/YourCues";
import { ErrorPage } from "Pages/ErrorPage";
import { Amplify } from "Pages/Amplify/Amplify";
import { MyDetails } from "Pages/Signup/MyDetails";
import { useAuthContext } from "hooks/useAuthContext";
import { Loader } from "components/Loaders/Loader";
import { Home } from "Pages/Signup/Home";
import { Footer } from "components/Footer";
import ScrollToTop from "Pages/ScrollToTop";
import { CollectCompliment } from "Pages/CollectCompliment";
import { STRINGS } from "assets/strings";
import Login from "Pages/Signup/Login";
import { RedirectIntent } from "Pages/Redirect/RedirectIntent";

const App = () => {
  const Auth = useAuthContext();
  const location = useLocation();

  // Delete the generated link from localStorage - if the user doesn't click on Done button after
  // sending API request, it has to be deleted once the user is out of the Share page
  useEffect(() => {
    if (location.pathname !== "/share") {
      localStorage.removeItem("genLink");
    }
  }, [location.pathname]);
  const RequireAuth = () => {
    return (
      <>
        {!Auth.auth_promise_resolved ? (
          <Loader />
        ) : !Auth.user ? (
          <Navigate to="/" state={{ path: location.pathname }} />
        ) : Auth.user.optional_data_json?.gender ? (
          <>
            <div className="min-h-[calc(100vh-240px)]">
              <Outlet />
            </div>
            <Footer />
          </>
        ) : (
          <Navigate to="/my-details" />
        )}
      </>
    );
  };
  return (
    // React Router Dom initialized with BrowserRouter

    <div className="font-primary">
      <ScrollToTop />
      <Routes>
        {/* "/" route navigated to login page */}
        <Route
          exact
          path="/"
          element={
            // First, the user details are fetched. <Loader/> is rendered.
            // Once they are fetched, auth_promise_resolved is set to true
            // If user login is successful, Auth.user exists and route is "/username", else it is "/"
            !Auth.auth_promise_resolved ? (
              <Loader />
            ) : Auth.user ? (
              <Navigate
                to={`/${Auth.user && Auth.user.username}`}
                replace
                state={location.state}
              />
            ) : (
              <Home />
            )
          }
        />
        <Route element={<RequireAuth />}>
          <Route exact path="/profile/:id/qualities" element={<YourCues />} />
          <Route exact path="/:id/qualities" element={<YourCues />} />
          <Route
            exact
            path={Auth.user && `/${Auth.user.username}`}
            element={
              Auth.user ? (
                <YourDashboard />
              ) : (
                <Navigate to="/" state={location.state} />
              )
            }
          />
          {/* Connections Profile with username=:username params */}
          <Route exact path="/:username" element={<ConnectionProfile />} />
          <Route exact path="/send" element={<SendCompliment />} />
          <Route exact path="/share" element={<Share />} />
          <Route
            exact
            path="/share/redirect/instagram"
            element={<RedirectIntent platform="instagram" />}
          />
          <Route
            exact
            path="/share/redirect/messenger"
            element={<RedirectIntent platform="messenger" />}
          />

          {/* Compliment received page */}
        </Route>
        <Route exact path="/amplify" element={<Amplify />} />
        <Route
          exact
          path="/login"
          element={
            !Auth.auth_promise_resolved ? (
              <Loader />
            ) : Auth.user ? (
              <Navigate to="/" />
            ) : (
              <Login />
            )
          }
        />
        <Route
          exact
          path={`/${STRINGS.CATEGORIES.AMAZING.toLowerCase()}/:id`}
          element={<CollectCompliment category={STRINGS.CATEGORIES.AMAZING} />}
        />
        <Route
          exact
          path={`/${STRINGS.CATEGORIES.CUTE.toLowerCase()}/:id`}
          element={<CollectCompliment category={STRINGS.CATEGORIES.CUTE} />}
        />
        <Route
          exact
          path="/my-details"
          element={
            Auth.auth_promise_resolved && Auth.user ? (
              <MyDetails />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="*"
          element={!Auth.auth_promise_resolved ? <Loader /> : <ErrorPage />}
        />
      </Routes>
    </div>
  );
};

export default App;
