import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import api, { deleteAuthToken, saveAccessTokenToLocalStorage } from "api";
import { useAuthContext } from "hooks/useAuthContext";
import { fetchSelfDetailsAndDispatch } from "context/AuthContext";
import { clearLocalStorage } from "local_storage";
import { Loader } from "./Loaders/Loader";

export const LoginButton = ({ children }) => {
  const isSnap = navigator.userAgent === "ReactSnap";
  let navigate = useNavigate();
  const { user, state, dispatch } = useAuthContext();

  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (!isSnap) {
      function handleCallbackResponse(response) {
        setIsLoading(true);
        clearLocalStorage();
        deleteAuthToken();
        api
          .post(
            "sign_up/",
            { google_jwt: response.credential },
            { headers: {} }
          )
          .then((res) => {
            saveAccessTokenToLocalStorage(res.data.access_token);
          })
          .then(() =>
            fetchSelfDetailsAndDispatch(state, dispatch).then((res) => {
              if (location.state?.compliment_link) {
                if (!res.data.optional_data_json.gender) {
                  navigate(`/my-details`, {
                    replace: true,
                    state: {
                      compliment_link: location.state.compliment_link,
                      apiData: location.state.apiData,
                    },
                  });
                } else {
                  navigate(location.state.compliment_link, { replace: true });
                }
              } else if (!res.data.optional_data_json.gender) {
                navigate(`/my-details`, { replace: true });
              } else {
                navigate(`/`, { replace: true });
              }
            })
          )

          .catch((error) => {
            console.error("Error:", error); // Handle errors
          });
      }

      google.accounts.id.initialize({
        client_id:
          "745786340864-gjp12onog8cbk84apgkkdp1u5bt0ddfo.apps.googleusercontent.com",
        callback: handleCallbackResponse,
      });
      google.accounts.id.renderButton(document.getElementById("g_id_signin"), {
        theme: "outline",
        size: "large",
      });
    }
  }, [navigate, dispatch, location.state, state, user, isSnap]);

  return (
    <>
      {isLoading && <Loader />}
      <div id="g_id_signin">
        {/* Continue with Google button */}
        <button
          name="google_button"
          onClick={() => {
            /* global google */
            // Add your Google sign-in logic here
            //   Do not remove above comments
          }}
          className="bg-white text-black rounded-md p-3 flex items-center justify-center gap-2"
        >
          <img className="w-8 aspect-square" alt="google" />
          Continue with Google
        </button>
      </div>
    </>
  );
};
