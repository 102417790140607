import React, { useEffect, useState } from "react";
import ComplimentBox from "./ComplimentBox";
import { ThreeDots } from "react-loader-spinner";
import { CategoryBar } from "./CategoryBar";
import { useNavigationContext } from "hooks/useNavigationContext";
import { STRINGS } from "assets/strings";
import { useAuthContext } from "hooks/useAuthContext";

const ZeroOrHiddenComponent = (text) => (
  <div className="flex-1 flex justify-center">
    <span className="text-gray-500">{text}</span>
  </div>
);

const Category = ({
  userProp,
  isDashboardLoading = false,
  tab = STRINGS.TABS.RECEIVED,
  compliments = {
    [STRINGS.TABS.RECEIVED]: {
      [STRINGS.CATEGORIES.AMAZING]: [],
      [STRINGS.CATEGORIES.CUTE]: [],
    },
    [STRINGS.TABS.SENT]: {
      [STRINGS.CATEGORIES.AMAZING]: [],
      [STRINGS.CATEGORIES.CUTE]: [],
    },
  },
}) => {
  const Navigation = useNavigationContext();
  const Auth = useAuthContext();

  const [category, setCategory] = useState(() => {
    return Navigation.category;
  });

  // Intelligent tab selection - When the user lands on the page, he is shown the category which has non-zero compliments
  useEffect(() => {
    if (compliments[tab][Navigation.category].length > 0) {
      setCategory(Navigation.category);
      return;
    }
    let initialCategory;

    if (
      compliments[tab].AMAZING.length === 0 &&
      compliments[tab].CUTE.length > 0
    ) {
      initialCategory = "CUTE";
    } else if (
      compliments[tab].CUTE.length === 0 &&
      compliments[tab].AMAZING.length > 0
    ) {
      initialCategory = "AMAZING";
    } else {
      initialCategory = "AMAZING";
    }

    setCategory(initialCategory);
  }, [tab, compliments, Navigation.category]);

  let ret = <></>;
  if ((userProp === "self" && Auth.user[tab] === 0) || userProp[tab] === 0)
    return (
      <div className="my-10">{ZeroOrHiddenComponent("No compliments yet")}</div>
    );

  ret = (
    <>
      {ret}
      <div className="flex flex-col w-full relative">
        <CategoryBar
          category_counts={
            userProp?.category_counts || Auth.user.category_counts
          }
          setCategory={setCategory}
          category={category}
          tab={tab}
          compliments={compliments}
        />
      </div>
    </>
  );
  // || userProp?.category_counts?.[category]?.total === 0 // remove this in the below condition if No `category` compliment
  //  is to be shown only in personal dashboard
  if (
    (userProp === "self" && compliments[tab][category]?.length === 0) ||
    userProp?.category_counts?.[tab]?.[category]?.TOTAL === 0
  ) {
    ret = (
      <>
        {ret}
        <div className="mb-40">
          {ZeroOrHiddenComponent(`No '${category}' compliments yet`)}
        </div>
      </>
    );
    return ret;
  }

  return isDashboardLoading ? (
    <div className="mx-auto flex items-center justify-center">
      <ThreeDots
        visible={true}
        height="80"
        width="60"
        color="#000"
        radius="9"
        ariaLabel="three-dots-loading"
      />
    </div>
  ) : (
    <div className="flex flex-col w-full relative">
      {ret}

      <div
        className={`${compliments[tab][category]?.length > 0 ? "pb-4" : ""}`}
      >
        <div className="flex flex-col gap-8">
          {compliments[tab][category]?.map((box, index) => (
            <div key={index}>
              <ComplimentBox compliment={box} tab={tab} userProp={userProp} />
            </div>
          ))}
        </div>
      </div>
      {userProp !== "self" &&
      userProp?.category_counts?.[tab]?.[category]?.HIDDEN > 0 ? (
        <>
          <div className="mt-4 mb-10">
            {ZeroOrHiddenComponent(
              `${
                userProp?.category_counts?.[tab]?.[category]?.HIDDEN
              } private compliment${
                userProp?.category_counts?.[tab]?.[category]?.HIDDEN > 1
                  ? "s"
                  : ""
              } hidden`
            )}
          </div>
        </>
      ) : (
        <></>
      )}
      {/* <div className="h-10 hidden sm:block absolute bottom-0 left-0 w-full bg-gradient-to-b from-transparent to-white"></div> */}
    </div>
  );
};

export default Category;
