export const STRINGS = {
  CATEGORIES: {
    CUTE: "CUTE",
    AMAZING: "AMAZING",
  },
  PRIVACY: {
    PRIVATE: "PRIVATE",
    PUBLIC: "PUBLIC",
  },
  TABS: {
    RECEIVED: "RECEIVED",
    SENT: "SENT",
  },
  COMPLIMENT_STATUS: {
    SENT: "SENT",
    SHOWN: "SHOWN",
    ACCEPTED: "ACCEPTED",
  },
  PRIVACY_KEYS: {
    SENDER: "SENDER_PRIVACY",
    RECEIVER: "RECEIVER_PRIVACY",
  },
};
