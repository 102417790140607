import React from "react";
import { useLocation } from "react-router-dom";

const Cue = ({ emoji, text, qty, className = "w-full", hidden }) => {
  const location = useLocation();
  return (
    <div
      className={`${className} bg-gray-100 rounded-2xl relative capitalize border-box`}
    >
      <div
        className={` ${
          location.pathname.includes("compliment") ? "" : "m-3"
        } bg-white rounded-xl flex items-center px-2 py-4 mobile:p-4 gap-4 ${
          hidden && "overflow-hidden"
        }`}
      >
        <div className="text-6xl mobile:text-7xl sm:text-8xl">{emoji}</div>
        <div
          className={`text-gray-700 leading-6 w-fit ${
            location.pathname.includes("compliment")
              ? "flex flex-col gap-0"
              : ""
          }`}
        >
          {text
            .split(",")
            .slice(0, 3)
            .map((value, index) => (
              // <div key={index} className="w-fit">
              <span
                key={index}
                className={`${
                  location.pathname.includes("compliment") ? "px-2" : ""
                } ${
                  index > 0 && hidden
                    ? "select-none relative after:absolute whitespace-nowrap px-1 py-1 after:inset-0 after:w-full after:h-full after:backdrop-blur-sm "
                    : ""
                }`}
              >
                {value}
                {index === 2 ? "" : ","}
              </span>
              // </div>
            ))}
        </div>
      </div>
      {qty && (
        <div className="absolute text-2xl font-bold bg-gray-600 text-white rounded-full w-8 flex items-center justify-center aspect-square -right-2 -top-2">
          {qty}
        </div>
      )}
    </div>
  );
};

export default Cue;
