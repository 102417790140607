import React from "react";

export const Carousel3 = () => {
  return (
    <>
      <div className="text-9xl">🙌🏼</div>
      <div className=" flex items-center justify-center gap-4 mt-20">
        <h1 className="text-2xl font-light text-center">
          Let&apos;s get you started!
        </h1>
      </div>
    </>
  );
};
