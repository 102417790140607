import React from "react";

export const Footer = () => {
  return (
    <div className="w-screen h-[200px] mt-10 flex items-center text-center  justify-center flex-col gap-4 bg-gray-200 py-8 px-6 text-gray-500 font-light">
      <div>&#169; 2024</div>
      <div>
        ithinkyouare is a kindness app with a mission to amplify the flow of
        compliments in the world
      </div>
      <div>#FindTheGoodInOthers</div>
    </div>
  );
};
